import React from 'react';

import ValidationTypes from '@util/ValidationTypes';

import useForm from '@hooks/useForm';

import FormInput from '@components/forms/FormInput';

export default function BidderDashboardEditProfile({ initFormData, handleEditSuccess, formRef, setEditedProfile }) {
    const editFormRules = {
        firstName: [ValidationTypes.requiredString],
        lastName: [ValidationTypes.requiredString],
        email: [ValidationTypes.requiredString],
        address: [ValidationTypes.requiredString],
        town: [ValidationTypes.requiredString],
        region: [ValidationTypes.requiredString],
        postCode: [ValidationTypes.requiredString],
        country: [ValidationTypes.requiredString],
        mobilePhoneNumber: [ValidationTypes.requiredString]
    };

    const { formData, handleChange, errorMapping, handleSubmit } = useForm(initFormData, editFormRules);

    const customHandleChange = (e) => {
        handleChange(e, null, (newFormData) => {
            if (
                initFormData.firstName === newFormData.firstName &&
                initFormData.lastName === newFormData.lastName &&
                initFormData.email === newFormData.email &&
                initFormData.address === newFormData.address &&
                initFormData.town === newFormData.town &&
                initFormData.region === newFormData.region &&
                initFormData.postCode === newFormData.postCode &&
                initFormData.country === newFormData.country &&
                initFormData.mobilePhoneNumber === newFormData.mobilePhoneNumber &&
                initFormData.landlinePhoneNumber === newFormData.landlinePhoneNumber
            ) {
                setEditedProfile(false);
            }
            else {
                setEditedProfile(true);
            }
        })
    };

    return (
        <form 
        className="flex flex-col space-y-5 mt-8 sm:mt-0 sm:w-4/5" 
        onSubmit={(e) => handleSubmit(e, () => handleEditSuccess(formData))}
        ref={formRef}>
            <FormInput 
            type="text"
            label="First Name"
            labelClass="edit-profile-label"
            inputName="firstName"
            value={formData.firstName}
            error={errorMapping.firstName}
            handleChange={customHandleChange}
            inline />
            <FormInput 
            type="text"
            label="Last Name"
            labelClass="edit-profile-label"
            inputName="lastName"
            value={formData.lastName}
            error={errorMapping.lastName}
            handleChange={customHandleChange}
            inline />
            <FormInput 
            type="text"
            label="Email"
            labelClass="edit-profile-label"
            inputName="email"
            value={formData.email}
            error={errorMapping.email}
            handleChange={customHandleChange}
            inline />
            <FormInput 
            type="text"
            label="Address"
            labelClass="edit-profile-label"
            inputName="address"
            value={formData.address}
            error={errorMapping.address}
            handleChange={customHandleChange}
            inline />
            <FormInput 
            type="text"
            label="Town"
            labelClass="edit-profile-label"
            inputName="town"
            value={formData.town}
            error={errorMapping.town}
            handleChange={customHandleChange}
            inline />
            <FormInput 
            type="text"
            label="Region"
            labelClass="edit-profile-label"
            inputName="region"
            value={formData.region}
            error={errorMapping.region}
            handleChange={customHandleChange}
            inline />
            <FormInput 
            type="text"
            label="Postcode"
            labelClass="edit-profile-label"
            inputName="postCode"
            value={formData.postCode}
            error={errorMapping.postCode}
            handleChange={customHandleChange}
            inline />
            <FormInput 
            type="text"
            label="Country"
            labelClass="edit-profile-label"
            inputName="country"
            value={formData.country}
            error={errorMapping.country}
            handleChange={customHandleChange}
            inline />
            <FormInput 
            type="text"
            label="Mobile Phone"
            labelClass="edit-profile-label"
            inputName="mobilePhoneNumber"
            value={formData.mobilePhoneNumber}
            error={errorMapping.mobilePhoneNumber}
            handleChange={customHandleChange}
            inline />
            <FormInput 
            type="text"
            label="Landline Phone"
            labelClass="edit-profile-label"
            inputName="landlinePhoneNumber"
            value={formData.landlinePhoneNumber}
            error={errorMapping.landlinePhoneNumber}
            handleChange={customHandleChange}
            inline />
        </form>
    )
}