import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import CircularProgress from '@material-ui/core/CircularProgress';

import AppContext from '@contexts/AppContext'
import { bidder } from '../../api/api';

export default function VerifyEmailApiPage() {
    const history = useHistory(); 
    const [err, setErr] = useState(null);
    const { agencyData: { agencyId } } = useContext(AppContext)

    useEffect(() => {
        if (!history.location.search) { 
            history.push('/notfound');
        }
        else {
            const queryObj = qs.parse(history.location.search.slice(1))
            bidder.post({
                endpoint: 'verifyEmail',
                data: { ...queryObj, agencyId },
                handleSuccess: () => history.push('/bidder/login?verified=true'),
                handleFail: (err) => setErr(err) 
            });
        }
    }, []);

    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center">
        {
            err ? (
                <>
                    <h1 className="font-primary font-bold text-2xl text-center">There was an error in verifying your email:</h1>
                    <p className="form-error-text my-3">{err}</p>
                    <p className="font-secondary font-semibold text-xl text-air-red">Please contact us at placeholder@email.com with the specified error so we can troubleshoot.</p>
                </>
            )
            : (
                <>
                    <CircularProgress size="5rem" color="secondary" />
                    <h1 className="font-primary font-bold text-3xl text-center mt-3">Verifying email...</h1>
                </>
            )
        }
        </div>


    )
}