import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ClearIcon from '@material-ui/icons/Clear';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import PropertyPageImageSlider from '@pages/property/components/PropertyPageImageSlider';

import FileUpload from '@components/forms/FileUpload';

export default function AgentFormImagesUpload({ formData, setFormData, errorMapping, imgErrs, getFileData, removeImg }) {
    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination || source.index === destination.index) {
            return ;
        }

        setFormData((formData) => { 
            let newImages = [...formData.assetImages];
            let movedImage = newImages[source.index];

            newImages.splice(source.index, 1);
            newImages.splice(destination.index, 0, movedImage);
            return {...formData, assetImages: newImages}; 
        });
    }

    const handleNewFile = (e) => {
        if (e.target.files.length) {
            let name = e.target.files[0].name;
            let nameSplit = name.split(".");
            let type = nameSplit[nameSplit.length - 1];
    
            if (!formData.assetImages.some((img) => img.name === name)) {
                getFileData(name, e.target.files[0], (result) => {
                    setFormData((formData) => {
                        return {
                            ...formData,
                            assetImages: [...formData.assetImages, {
                                name,
                                data: result,
                                type
                            }]
                        };
                    })
                });
            }
        }
    }

    const handleDeleteFile = (e, idx) => {
        e.preventDefault();

        setFormData((formData) => {
            let newImages = [...formData.assetImages];

            newImages.splice(idx, 1);
            return {...formData, assetImages: newImages};
        })

        if (!formData.assetImages[idx].data || formData.assetImages[idx].data.startsWith('data')) {
            removeImg(formData.assetImages[idx].name);
        }
    }

    return (
        <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5">
            <div className="pb-12 sm:pb-0 sm:pr-5 border-b-4 sm:border-b-0 sm:border-r-4 border-primary-agency w-full">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="image-list">
                    {
                        (provided, snapshot) => {
                            return (
                                <ul
                                style={{maxHeight: "30rem"}}
                                className={`rounded-lg list-none m-0 p-3 pb-0 overflow-y-auto transition-colors duration-300 ease-in-out ${snapshot.isDraggingOver ? 'bg-table-light-blue' : ''}`}
                                ref={provided.innerRef}
                                {...provided.droppableProps}>
                                {
                                    formData.assetImages.map((img, idx) => {
                                        return (
                                            <Draggable 
                                            key={img.name}
                                            draggableId={img.name}
                                            index={idx}>
                                            {
                                                (provided) =>
                                                <li
                                                className="mb-3"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}>
                                                    <div className={`flex items-center space-x-5 px-2 py-5 sm:p-5 text-sm sm:text-base border-2 border-dark-gray ${imgErrs[img.name] && 'border-air-red'} bg-white rounded-lg w-full`}>
                                                        <div {...provided.dragHandleProps}>
                                                            <DragIndicatorIcon  />
                                                        </div>
                                                        <span className="font-primary font-semibold w-full">{img.name}</span>
                                                        <div className="flex justify-end">
                                                            <ClearIcon className="cursor-pointer" onClick={(e) => handleDeleteFile(e, idx)}/>
                                                        </div>
                                                    </div>
   
                                                    {imgErrs[img.name] && <span className="form-error-text mt-2 leading-tight">{imgErrs[img.name]}</span>}
                                                </li>
                                            }
                                            </Draggable>
                                        )
                                    })
                                }
                                {provided.placeholder}
                                </ul>
                            )
                        }
                    }
                    </Droppable>
                </DragDropContext>
                <div className="flex justify-center mt-5">
                    <FileUpload 
                    inputId="property-image-upload"
                    accept=".jpeg,.jpg,.png"
                    handleChange={handleNewFile} 
                    error={errorMapping.assetImages}
                    submitText="Add an Image"
                    imageTitle="add-photo"
                    imagePosition="left" />
                </div>

            </div>
            <div className="w-full">
                <h2 className="font-primary font-bold text-4xl text-center">Preview</h2>
                <p className="font-secondary font-bold font-sm mb-5 text-primary-agency text-center">This is how your images will be laid out on a property page.</p>
                {
                    !formData.assetImages.length
                    ?
                    (
                        <h3 className="font-secondary font-black text-air-red text-2xl text-center">No images found</h3>
                    )
                    :
                    (
                        <PropertyPageImageSlider 
                        images={formData.assetImages.filter((img) => img.data).map((img) => img.data)}
                        inDB={formData.assetImages.filter((img) => img.data).map((img) => !img.data.startsWith('data'))}
                        preview
                        slideStyle={{backgroundColor: "white"}} 
                         />  
                    )
                }
            </div>  
        </div>
    )
}