import React, { useContext, useMemo } from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ErrorIcon from '@material-ui/icons/Error';
import Accordion from '@material-ui/core/Accordion';

import SelectFields from '@util/SelectFields';

import AppContext from '@contexts/AppContext';

import AgentFormRTE from './AgentFormRTE';
import AgentFormEditRTE from './AgentFormEditRTE';
import FormInput from '@components/forms/FormInput';
import Select from '@components/forms/Select';

function AgentFormGeneralProperty({ mode, formData, setFormData, errorMapping, handleChange, sectionError }) {
    const { agencyData: { branches } } = useContext(AppContext);
    const branchOptions = useMemo(() => {
        return [{
            name: '',
            value: ''
        }, ...branches.map(({ name, branchId }) => ({
            name,
            value: branchId
        }))];
    }, [branches]);

    return (    
        <div className="mt-10">
            <Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ArrowDropDownIcon fontSize="large" color="secondary" />}>
                    <div className="flex items-center text-2xl sm:text-4xl">
                    {
                        sectionError && <ErrorIcon className="mr-3" color="error" fontSize="inherit" />
                    }
                        <h1 className="flex items-center font-primary font-semibold text-white text-xl sm:text-3xl">General Property Information</h1>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="flex flex-col lg:flex-row justify-center space-y-5 lg:space-x-5 py-3 sm:p-3 md:p-8 sm:pb-10 w-full">
                        <div className="space-y-6 pb-10 lg:pb-0 lg:pr-5 border-b-4 lg:border-r-4 lg:border-b-0 border-primary-agency w-full">
                            <FormInput 
                            type="text"
                            label="Property Title"
                            inputName="title"
                            value={formData.title}
                            error={errorMapping.title}
                            handleChange={handleChange} />
                            <FormInput
                            type="text"
                            label="Address Line 1"
                            inputName="address"
                            value={formData.address}
                            error={errorMapping.address}
                            handleChange={handleChange} />
                            <FormInput
                            type="text"
                            label="Address Line 2 (Optional)"
                            inputName="address2"
                            value={formData.address2}
                            error={errorMapping.address2}
                            handleChange={handleChange} />
                            <div className="flex space-x-5">
                                <FormInput
                                type="number"
                                // inputProps={{max: 90, min: -90}}
                                label="Latitude"
                                inputName="lat"
                                value={formData.lat}
                                error={errorMapping.lat}
                                handleChange={handleChange}
                                rootClass="w-1/2" />
                                <FormInput
                                type="number"
                                // inputProps={{max: 180, min: -180}}
                                label="Longitude"
                                inputName="lng"
                                value={formData.lng}
                                error={errorMapping.lng}
                                handleChange={handleChange} 
                                rootClass="w-1/2" />
                            </div>
                            <div className="flex space-x-5">
                                <FormInput
                                type="text"
                                label="Town"
                                inputName="town"
                                value={formData.town}
                                error={errorMapping.town}
                                handleChange={handleChange}
                                rootClass="w-3/5" />
                                <FormInput
                                type="text"
                                label="Postcode"
                                inputName="postCode"
                                value={formData.postCode}
                                error={errorMapping.postCode}
                                handleChange={handleChange} 
                                rootClass="w-2/5" />
                            </div>
                            <FormInput
                            type="text"
                            label="County"
                            inputName="county"
                            value={formData.county}
                            error={errorMapping.county}
                            handleChange={handleChange} />
                            <Select
                            label="Property Class"
                            name="assetClass"
                            value={formData.assetClass}
                            options={['', 'Residential', 'Commercial']}
                            nameValueEq
                            error={errorMapping.assetClass}
                            onChange={handleChange} />
                            <Select 
                            label="Property Type"
                            name="assetType"
                            value={formData.assetType}
                            options={['', 'Terraced', 'Semi-Detached', 'Detached', 'Bungalow', 'Flat', 'Land', 'Other']}
                            nameValueEq
                            error={errorMapping.assetType} 
                            onChange={handleChange} />
                            {
                                formData.assetType === "Other" && 
                                (
                                    <FormInput 
                                    type="text"
                                    label="Other Property Type"
                                    inputName="otherAssetType"
                                    value={formData.otherAssetType}
                                    error={errorMapping.otherAssetType}
                                    handleChange={handleChange} />
                                )
                            }
                            {
                                !(formData.assetType === "Land" || formData.assetType === "Other") && 
                                (
                                    <div className="flex flex-col sm:flex-row sm:space-x-5">
                                        <Select 
                                        label="# of Bedrooms"
                                        name="bedRooms"
                                        value={formData.bedRooms}
                                        options={SelectFields.number} 
                                        error={errorMapping.bedRooms}
                                        onChange={handleChange}
                                        rootClass="mb-6 sm:mb-0" />
                                        <Select 
                                        label="# of Bathrooms"
                                        name="bathRooms"
                                        value={formData.bathRooms}
                                        options={SelectFields.number}
                                        error={errorMapping.bathRooms}
                                        onChange={handleChange} />
                                    </div>
                                )
                            }
                            <FormInput
                            type="text"
                            label="Reference No."
                            inputName="skewCode"
                            value={formData.skewCode}
                            error={errorMapping.skewCode}
                            handleChange={handleChange}
                             />
                            <Select 
                            label="Branch Name"
                            name="branchId"
                            value={formData.branchId}
                            options={branchOptions}
                            error={errorMapping.branchId} 
                            onChange={handleChange} />
                        </div>
                        <div className="w-full">
                            <div>
                                <label className="form-input-label text-2xl" htmlFor="details">Property Description</label>
                                <p className="font-secondary font-bold text-sm sm:text-base text-primary-agency mb-5">Enter a general description of your property.</p>
                                {
                                    mode === 'edit' ? <AgentFormEditRTE initDetails={formData.details} setFormData={setFormData} /> : <AgentFormRTE setFormData={setFormData} />
                                }
                                <div className="relative">
                                    {errorMapping.details && <span className="form-error-text absolute mt-1 leading-tight">{errorMapping.details}</span>}
                                </div>
                            </div>
                            {/* <AgentDashboardFeatureList 
                            formData={formData} 
                            setFormData={setFormData} 
                            errorMapping={errorMapping} /> */}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default React.memo(AgentFormGeneralProperty);