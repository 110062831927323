import React, { useEffect, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { GOOGLE_RECAPTCHA_SITE_KEY } from '@util/site-constants'

export default function GoogleReCAPTCHA({value, handleChange, inputName, rootClass, error, inline, errorClass}) {
  const recaptchaRef = useRef(null)

  useEffect(() => {
    if (!value) {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset()
      }
    }
  }, [value])

  const changeHandler = (response) => {
    const target = {
      name: inputName,
      value: response
    }
    handleChange({target})
  }

  return (
    <div className={`relative flex w-full ${rootClass ? rootClass : ''} text-black ${inline ? 'sm:flex items-center' : ''}`}>
      <div className={`${error ? 'border-red-500 border-2 rounded-lg p-2' : ''} `}>
        <ReCAPTCHA ref={recaptchaRef} sitekey={GOOGLE_RECAPTCHA_SITE_KEY} onChange={changeHandler} />
      </div>
      {
          error && (
              <div className={`absolute ${inline ? 'top-full w-full sm:top-0 sm:left-full sm:h-full sm:flex sm:items-center' : 'top-full w-full'} ${errorClass}`}>
                  <span className={`form-error-text ${inline ? 'mt-1 sm:ml-3' : 'mt-1'} leading-tight`}  style={{minWidth: "130px"}}>{error}</span>
              </div>
          )
      }
    </div>
  )
}
