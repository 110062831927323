import React, { useEffect } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default function AgentFormEditRTE({ initDetails, setFormData }) {    
    useEffect(() => {
        let quill = new Quill('.quillrte', {
            modules: {
                toolbar: [
                    [{ font: [] }, { header: [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ color: [] }, { background: [] }],
                    [{ 'script': 'sub'}, { 'script': 'super' }],
                    ['blockquote', { list: 'ordered'}, { list: 'bullet' }],
                    [{ 'indent': '-1'}, { 'indent': '+1' }, { align: [] }]
                ]
            },
            placeholder: 'Enter property description...',
            theme: 'snow',
        })

        quill.setContents(JSON.parse(initDetails));

        quill.on('text-change', function(delta, oldDelta, source) {
            setFormData((formData) => ({ ...formData, details: JSON.stringify(quill.getContents()) }));
        });
    }, []);


    return (
        <div className="quillrte">
        </div>
    )
}