import React, { useEffect, useState } from 'react';
import Quill from 'quill';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ClearIcon from '@material-ui/icons/Clear';

import PropertyPageImageSlider from '@pages/property/components/PropertyPageImageSlider';

import Tabs from '@components/Tabs';
import DocumentView from '@components/DocumentView';

export default function AgentDashboardPropertyMoreInfo({ open, handleClose, propertyData }) {
    const [contentRendered, setContentRendered] = useState(false);

    useEffect(() => {
        if (contentRendered) {
            new Quill('.quill-description', {
                readOnly: true
            }).setContents(JSON.parse(propertyData.details));
        }

    }, [contentRendered]);

    return (
        <Modal
        className="flex justify-center items-center"
        open={open}
        closeAfterTransition
        onClose={() => { setContentRendered(false); handleClose(); } }
        BackdropComponent={Backdrop}
        onRendered={() => setContentRendered(true)}
        BackdropProps={{
            appear: true,
            enter: 500,
            exit: 750
        }}>
            <Fade in={open}>
                <div className="relative outline-none w-4/5 p-3">
                    <ClearIcon className="absolute top-0 right-0 mt-6 mr-6 cursor-pointer" onClick={(e) => handleClose()} />
                    <Tabs
                    tabList={["Property Details", "Branch Details", "Auction Details", "Images and Documents"]}
                    tabClass="text-base">
                        <section className="flex">
                            <div className="w-1/2">
                                <div className="flex space-x-3">
                                    <div className="w-1/2">
                                        <h4 className="readonly-label">Property Class</h4>
                                        <span className="readonly-value">{propertyData.assetClass}</span>
                                    </div>
                                    <div className="w-1/2">
                                        <h4 className="readonly-label">Property Type</h4>
                                        <span className="readonly-value">{propertyData.assetType}</span>
                                    </div>
                                </div>
                                <div className="flex space-x-3 mt-4">
                                    <div className="w-1/2">
                                        <h4 className="readonly-label">Bedrooms</h4>
                                        <span className="readonly-value">{propertyData.bedRooms}</span>
                                    </div>
                                    <div className="w-1/2">
                                        <h4 className="readonly-label">Bathrooms</h4>
                                        <span className="readonly-value">{propertyData.bathRooms}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/2">
                                <h4 className="readonly-label">Description</h4>
                                <p className="readonly-value overflow-y-auto mt-2 more-info-property__description quill-description"></p>
                            </div>
                        </section>
                        <section className="flex"> 
                            <div className="w-1/3 flex items-center text-primary text-6xl">
                                <HomeIcon className="mr-3" fontSize="inherit" />
                                <div>
                                    <h4 className="readonly-label text-xl">Branch Address:</h4>
                                    <p className="readonly-value text-black text-lg">{propertyData.branchDetails}</p>
                                </div>
                            </div>
                            <div className="w-1/3 flex items-center text-primary text-6xl">
                                <PersonIcon className="mr-5" fontSize="inherit" />
                                <div>
                                    <h4 className="readonly-label text-xl">Contact Name:</h4>
                                    <p className="readonly-value text-black text-lg">{propertyData.assetContactName}</p>
                                </div>
                            </div>
                            <div className="w-1/3 flex items-center text-primary text-6xl">
                                <PhoneIcon className="mr-3" fontSize="inherit" />
                                <div>
                                    <h4 className="readonly-label text-xl">Contact Telephone:</h4>
                                    <p className="readonly-value text-black text-lg">{propertyData.assetContactTelephone}</p>
                                </div>
                            </div>
                        </section>
                        <section className="flex">
                            <div className="w-1/3 flex text-primary text-4xl">
                                <ScheduleIcon className="mr-3" fontSize="inherit" />
                                <div>
                                    <div>
                                        <h4 className="readonly-label text-lg">Publish Date</h4>
                                        <p className="readonly-value text-black text-base">{propertyData.publishDate || 'N/A'}</p>
                                    </div>
                                    <div className="mt-4">
                                        <h4 className="readonly-label text-lg">Bid End Time</h4>
                                        <p className="readonly-value text-black text-base">{propertyData.bidEndTime || 'N/A'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/3 flex">
                                <span className="material-icons mr-3 text-4xl text-primary">payments</span>
                                <div>
                                    <div className="flex space-x-8">
                                        <div>
                                            <h4 className="readonly-label text-lg">Reserve Price</h4>
                                            <p className="readonly-value">{propertyData.reservePrice === -1 ? 'N/A' : `£${propertyData.reservePrice}`}</p>
                                        </div>
                                        <div>
                                            <h4 className="readonly-label text-lg">Guide Price</h4>
                                            <p className="readonly-value">{propertyData.guidePrice !== -1 ? `£${propertyData.guidePrice}` : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h4 className="readonly-label text-lg">Bid Start Price</h4>
                                        <p className="readonly-value">£{propertyData.bidStartPrice}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/3 flex">
                                <span className="material-icons mr-3 text-4xl text-primary">history_edu</span>
                                <div>
                                    <div>
                                        <h4 className="readonly-label text-lg">Current Highest Bid</h4>
                                        <p className="readonly-value">{propertyData.bidHistory.length ? 'In Progress' : 'N/A'}</p>
                                    </div>
                                    <div className="mt-4">
                                        <h4 className="readonly-label text-lg">View Bid History</h4>
                                        <p className="readonly-value">{propertyData.bidHistory.length ? 'In Progress' : 'N/A'}</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="flex">
                            <div className="w-1/2">
                                <h4 className="readonly-label text-3xl text-center">Images</h4>
                                <PropertyPageImageSlider 
                                images={propertyData.assetImages} 
                                viewStyle={{height: "250px"}} 
                                slideStyle={{backgroundColor: "white"}}
                                rootClass="p-5" />
                            </div>
                            <div className="w-1/2">
                                <h4 className="readonly-label text-3xl text-center mb-5">Documents</h4>
                                <DocumentView documents={propertyData.assetDocuments} rootClass="p-5 border-2 border-primary-agency rounded-lg" />
                                {/* <ul className="more-info-docs__grid grid p-5 border-2 border-primary rounded-lg">
                                {
                                    propertyData.assetDocuments.map((doc) => (
                                        <li>
                                            <a className="flex flex-col items-center text-6xl hover:bg-table-light-blue p-5 rounded-lg" href={`http://157.245.34.50:9080/${doc.path}`} target="_blank">
                                                <DescriptionIcon fontSize="inherit" />
                                                <span className="font-primary font-semibold text-lg break-all">{doc.title}</span>
                                            </a>
                                        </li>
                                    ))
                                }
                                </ul> */}
                                
                            </div>
                        </section>
                    </Tabs>
                </div>

            </Fade>
        </Modal>
    )
}