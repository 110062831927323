import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { bidder } from '@api/api';

import AppContext from '@contexts/AppContext';

import ValidationTypes from '@util/ValidationTypes';

import useForm from '@hooks/useForm';

import FormInput from '@components/forms/FormInput';
import InputButton from '@components/forms/InputButton';

const EmailSent = () => {
    return (
        <div className="flex justify-center items-center min-h-screen">
            <div className="w-1/2 lg:w-1/3 min-w-mobile rounded-lg bg-white bg-opacity-90">
                <div className="flex items-center rounded-lg rounded-b-none bg-primary-agency text-center py-5 px-5 sm:px-10">
                    <MailOutlineIcon className="text-white" fontSize="large" />
                    <h1 className="font-primary font-semibold ml-3 text-3xl text-white leading-tight">Email Sent!</h1>
                </div>
                <p className="font-secondary text-lg lg:text-xl p-5">
                    You will shortly receive an email with further instructions to reset your password. Please check your spam folder as well.
                </p>
            </div>
        </div>

    )
};

export default function BidderForgotPassword() {
    const { agencyData: { agencyId } } = useContext(AppContext);
    const [resetError, setResetError] = useState('');
    const history = useHistory();

    const validationRules = {
        email: [ValidationTypes.requiredString, ValidationTypes.validEmail]
    };

    const { formData, handleChange, errorMapping, handleSubmit } = useForm({
        email: ''
    }, validationRules);

    const handleSubmitSuccess = () => {
        bidder.post({
            endpoint: 'resetPassword',
            data: { ...formData, agencyId },
            handleSuccess: (_) => setResetError(null),
            handleFail: (err) => setResetError(err)
        })
    };

    return (
        <div>
            <div className="fixed register-bg bg-center bg-fixed top-0 left-0 right-0 bottom-0 -z-1" />
            {
                resetError === null
                ?
                (
                    <EmailSent />
                )
                :
                (
                    <div className="w-full flex justify-center pt-24">
                        <form
                        onSubmit={(e) => handleSubmit(e, handleSubmitSuccess)}
                        className="w-5/6 sm:w-1/2 lg:w-1/3 min-w-mobile rounded-lg bg-white bg-opacity-90">
                            <div className="rounded-lg rounded-b-none bg-primary-agency text-center py-5 px-5 sm:px-10">
                                <h1 className="font-primary font-semibold text-3xl text-white">Forgot Password</h1>
                                <p className="text-secondary text-sm font-secondary">
                                    In order to reset your password, please provide your email address for further instructions.
                                </p>
                            </div>
                            <div className="p-5">
                                <FormInput 
                                type="text"
                                label="Email"
                                placeholder="Enter your email"
                                inputName="email"
                                value={formData.email}
                                error={errorMapping.email}
                                handleChange={handleChange} />
                                {
                                    resetError &&
                                    (
                                        <div className="flex justify-center mt-5">
                                            <span className="font-primary font-black text-red-500">{resetError}</span>
                                        </div>
                                    )
                                }
                                <div className="flex justify-center space-x-3 mt-8">
                                    <InputButton
                                    type="submit"
                                    submitText="Send" />
                                    <InputButton
                                    type="button"
                                    submitText="Cancel"
                                    inputStyle="inverse"
                                    onClick={() => history.push('/bidder/login')} />
                                </div>

                            </div>
                        </form>
                    </div>
                )
            }
            
        </div>
    )
}