export const initOptions = {
  minPrice: { name: 'Min Price', value: '-1' },
  bedRooms: [
    { name: '0+ Beds', value: '0' },
    { name: '1+ Beds', value: '1' },
    { name: '2+ Beds', value: '2' },
    { name: '3+ Beds', value: '3' },
    { name: '4+ Beds', value: '4' },
  ],
  maxPrice: { name: 'Max Price', value: '-1' },
}

export const formValidationRules = {
  minPrice: [
    {
      test: (value, formData) =>
        formData.maxPrice === '-1' ||
        parseInt(value) <= parseInt(formData.maxPrice),
      errorMsg: 'Must be less than max price',
    },
  ],
}