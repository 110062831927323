import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

import placeholder from '@static/placeholder_property.png';

import { IMG_BASE_URL } from '@util/site-constants';

function PropertyPageImageSlider({ images, preview, inDB, viewStyle, slideStyle, containerStyle, rootClass }) {
    const [currIdx, setCurrIdx] = useState(0);

    return (
        <div className={`relative ${rootClass || ''} ${!images.length ? 'mb-5' : ''}`}>
            <div className="relative overflow-hidden">
                <div className="relative h-0 pb-16/9">
                    {!!images.length && (
                        <>
                            <button 
                            className="property-p-left-arrow"
                            onClick={(e) => {
                                if (currIdx - 1 !== -1) {
                                    setCurrIdx(currIdx - 1);
                                }
                            }}
                            >
                                <KeyboardArrowLeftIcon />
                            </button>
                            <button 
                            className="property-p-right-arrow"
                            onClick={(e) => {
                                if (currIdx + 1 !== images.length) {
                                    setCurrIdx(currIdx + 1);
                                }
                            }}>
                                <KeyboardArrowRightIcon className="transform translate-x-px"/>
                            </button>
                        </>
                    )}
                    <SwipeableViews 
                    index={currIdx} 
                    onChangeIndex={(idx, idxLatest, meta) => {
                        if (currIdx !== idx) {
                            setCurrIdx(idx);
                        }
                    }}
                    containerStyle={{
                        height: "100%",
                        position: "absolute",
                        width: "100%",
                        ...viewStyle
                    }}

                    enableMouseEvents>
                    {
                        images.length ? (
                            images.map((image, idx) => {
                                return (
                                <img 
                                key={idx}
                                loading="lazy"
                                className="w-full object-cover h-full cursor-grab"
                                src={preview ? (inDB && inDB[idx] ? `${IMG_BASE_URL}/${image}` : image) : `${IMG_BASE_URL}/${image.path}`} 
                                alt="" />
                                );
                            })
                        )
                        :
                        (
                            <img 
                            src={placeholder} 
                            loading="lazy"
                            className="w-full object-cover h-full"
                            alt="Placeholder" />
                        )
                    }
                    </SwipeableViews>
                </div>
                
                {
                    images.length ? (
                        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex justify-center mb-3 px-3 py-2 bg-white rounded-lg opacity-75 w-28">
                            <CameraAltOutlinedIcon className="mr-3" />
                            <span>{currIdx + 1} / {images.length}</span>
                        </div>
                    ) : null
                }
            </div>

            {
                images.length ? (
                    <div className="flex justify-center items-center space-x-5 mini-slider">
                        <button 
                        tabIndex="0"
                        type="button"
                        className="leading-none p-3 cursor-pointer bg-white rounded-full opacity-75 shadow-lg"
                        onClick={(e) => currIdx - 1 !== -1 && setCurrIdx(currIdx - 1)} >
                            <KeyboardArrowLeftIcon />
                        </button>
                        <SwipeableViews
                        index={currIdx}
                        onChangeIndex={(idx, idxLatest, meta) => {
                            if (currIdx !== idx) {
                                setCurrIdx(idx);
                            }
                        }}
                        style={{
                            display: "flex",
                            width: "80%"
                        }}
                        containerStyle={{
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            ...containerStyle
                        }}
                        slideStyle={{
                            paddingRight: images.length === 1 ? '0rem' : '1rem',
                            overflow: "hidden",
                            ...slideStyle
                        }}
                        >
                        {
                            images.map((image, idx) => {
                                return (
                                <img 
                                key={idx}
                                loading="lazy"
                                className={`${currIdx === idx && 'opacity-50'} ${currIdx !== idx && 'transition-transform duration-200 ease-in origin-right transform hover:scale-105 cursor-pointer'}
                                w-full object-cover h-full`}
                                src={preview ? (inDB && inDB[idx] ? `${IMG_BASE_URL}/${image}` : image) : `${IMG_BASE_URL}/${image.path}`} 
                                onClick={(e) => setCurrIdx(idx)}
                                alt="" />
                                );
                            })
                        }
                        </SwipeableViews>
                        <button
                        tabIndex="0"  
                        type="button"  
                        className="leading-none p-3 cursor-pointer bg-white rounded-full opacity-75 shadow-lg"
                        onClick={(e) => currIdx + 1 !== images.length && setCurrIdx(currIdx + 1)}>
                            <KeyboardArrowRightIcon className="transform translate-x-px"/>
                        </button>
                    </div>
                ) : null
            }
        </div>
    )
}

export default React.memo(PropertyPageImageSlider, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.images) === JSON.stringify(nextProps.images);
});
