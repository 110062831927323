import React, { useState } from 'react';

import InputButton from '@components/forms/InputButton';
import DashboardChangePassword from '@components/dashboard/DashboardChangePassword';

export default function BidderDashboardHomePasswordSection({ bidderToken, bidderSecret, addNotification }) {
    const [showForm, setShowForm] = useState(false);

    return (
        <>
            <header>
                <h2 className="font-semibold text-2xl">Change Password</h2>
                <p className="font-semibold text-gray-700 leading-tight">Here you may change the password to your bidder account. You'll need to know your current password.</p>
            </header>
            {
                showForm ? (
                    <DashboardChangePassword 
                    token={bidderToken}
                    secret={bidderSecret} 
                    handleCancel={() => setShowForm(false)} 
                    addNotification={addNotification} 
                    rootClass="p-3 pl-0" />
                ) : (
                    <InputButton 
                    submitText="Change Password"
                    rootClass="mx-auto mt-5"
                    onClick={() => setShowForm((showForm) => !showForm)} />
                )
            }
            <div>

            </div>
        </>
    )
}