import { useState, useEffect, useRef } from 'react';

export default function BaseTimer({ startTime, endTime, handleEnd, children }) {
    const [timeLeft, setTimeLeft] = useState({});
    const timeLeftRef = useRef(endTime - startTime);

    useEffect(() => {
        timeLeftRef.current = endTime - startTime;
    }, [startTime, endTime]);

    useEffect(() => {
        var worker = new Worker('../../timer-worker.js');
        setTimeLeft({
            days: Math.floor(timeLeftRef.current / (1000 * 60 * 60 * 24)),
            hours: Math.floor((timeLeftRef.current % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((timeLeftRef.current % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((timeLeftRef.current % (1000 * 60)) / 1000)
        });
        timeLeftRef.current -= 1000;

        function handleWorkerMessage(e) {
            if (timeLeftRef.current <= 0) {
                handleEnd && handleEnd();
                worker.removeEventListener('message', handleWorkerMessage); // Make sure to remove event listener
                worker.terminate();
            }
            else {
                setTimeLeft({
                    days: Math.floor(timeLeftRef.current / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((timeLeftRef.current % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    minutes: Math.floor((timeLeftRef.current % (1000 * 60 * 60)) / (1000 * 60)),
                    seconds: Math.floor((timeLeftRef.current % (1000 * 60)) / 1000)
                });

                timeLeftRef.current -= 1000;
            }
        }
        worker.addEventListener('message', handleWorkerMessage);

        return () => {
            worker.removeEventListener('message', handleWorkerMessage);
            worker && worker.terminate();
        }
    }, []);

    return (
        children(timeLeft)
    );
}