import React, { useEffect } from 'react';
import Quill from 'quill';

export default function PropertyPageDescription({ details }) {
    useEffect(() => {
        new Quill('.quill-description', {
            readOnly: true
        }).setContents(JSON.parse(details));
    }, []);

    return (
        <section className="quill-description font-secondary"></section>
    )
}
