import React from 'react';

function FormInput({ type, label, placeholder, inputName, value, radioChecked, error, className, labelClass, rootClass, containerClass, errorClass, inputClass, handleChange, dataIdx, helperText, inputProps, inline, disabled }) {
    return (
        <div className={`relative w-full ${rootClass ? rootClass : ''} text-black ${inline ? 'sm:flex items-center' : ''}`}>
            <div className={`flex ${type === 'checkbox' || type === 'radio' ? 'flex-row-reverse' : 'flex-col'} ${containerClass ?? ''}`}>
                {
                    label && (
                        <label 
                        className={`form-input-label ${type === 'checkbox' || type === 'radio' ? 'mb-0 ml-3': ''} ${labelClass ? labelClass : ''}`}
                        htmlFor={inputName}>{label}</label>
                    )
                }
                {helperText && <p className="font-secondary font-bold text-primary-agency text-sm mb-3">{helperText}</p>}
                <input 
                id={inputName}
                className={`form-input ${type === 'checkbox' || type === 'radio' ? 'flex-grow-0 w-auto mt-1' : 'flex-grow'} ${disabled ? 'hover:border-dark-gray focus:border-dark-gray' : ''} ${error ? 'form-input-error' : ''} ${className} ${inputClass} ${inline ? 'text-center sm:text-left' : ''}`}
                type={type}
                name={inputName}
                {...{
                    ...(type !== 'checkbox' ? { value } : { checked: value })
                }}
                {
                    ...(radioChecked && { checked: radioChecked })
                }
                placeholder={placeholder}
                onChange={handleChange}
                data-idx={dataIdx} 
                {...inputProps}
                disabled={disabled} />
            </div>
            {
                error && (
                    <div className={`absolute ${inline ? 'top-full w-full sm:top-0 sm:left-full sm:h-full sm:flex sm:items-center' : 'top-full w-full'} ${errorClass}`}>
                        <span className={`form-error-text ${inline ? 'mt-1 sm:ml-3' : 'mt-1'} leading-tight`}  style={{minWidth: "130px"}}>{error}</span>
                    </div>
                )
            }


        </div>
    )
}

export default React.memo(FormInput);