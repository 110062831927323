import axios from '@util/axios'

/**
 * firstName: '',
 * lastName: '',
 * email: '',
 * password: '',
 * password2: '',
 * mobilePhoneNumber: '',
 * landlinePhoneNumber: '',    
 * terms: false,
 * newsletter: formData.newsletter === 'yes',
 * agencyId,
 * bidderType: "individual"
 * recaptcha: (google reCAPTCHA)
 */
export const signUpBidder = async (options) => {
  try {
    const { data } = await axios.post('/api/bidder/signup', options)
    return data
  } catch(err) {
    return null
  }
}