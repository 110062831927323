import { parseISO } from 'date-fns'
import { useMemo } from 'react'

export const bidEndTimeSortFn = (dir) => (prop1, prop2) => {
  // live -> pre-market -> ended
  if (prop1.liveState !== prop2.liveState) {
    return dir * (prop2.liveState - prop1.liveState)
  }
  // live
  if (prop1.liveState === 0) {
    return dir * (prop2.endTimeParsed - prop1.endTimeParsed)
  }
  // ended | pre-market
  return dir * (prop1.endTimeParsed - prop2.endTimeParsed)
}

export const sortOptions = {
  sortFields: [
    {
      value: 'bidEndTime',
      name: 'Ending Soonest',
      dir: -1,
    },
    {
      value: 'createdDate',
      name: 'Newly Listed',
      dir: -1,
    },
    {
      value: 'bidStartPrice',
      name: 'Price Lowest to Highest',
      dir: 1,
    },
    {
      value: 'bidStartPrice',
      name: 'Price Highest to Lowest',
      dir: -1,
    },
  ],
  sortFns: {
    // If no sort func for a particular field, then assume it is just normal sorting.
    // Make use of the pre-added-property 'liveState' to sort first;
    bidEndTime: bidEndTimeSortFn, 
    bidStartPrice: (dir) => (prop1, prop2) =>
      dir * (prop1.bidPrice - prop2.bidPrice),
    createdDate: (dir) => (prop1, prop2) =>
      dir *
      (parseISO(prop1.createdDate).getTime() -
        parseISO(prop2.createdDate).getTime()),
  },
}

export default function usePropertySortFn(sortName = 'Ending Soonest') {
  const sortFn = useMemo(() => {
    const sortFnProperties = sortOptions.sortFields.find(
      ({ name }) => name === sortName
    )
    return sortOptions?.sortFns[sortFnProperties.value](sortFnProperties.dir)
  }, [sortName])

  return sortFn
}
