import React, { useState } from 'react';

import BidHistoryTable from './BidHistoryTable';
import InputButton from './forms/InputButton';

const DEFAULT_BIDS = 4;

export default function BidHistorySection({ isBidder, bidHistory, bidderAlias }) {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <div className="bg-white shadow-lg p-5">
            <header className="flex justify-between">
                <h2 className="font-primary font-bold text-xl">BID HISTORY</h2>
                {
                    isBidder && (
                        <p className="font-primary font-bold text-xl">{bidHistory.length} BIDS</p>
                    )
                }
            </header>
            {
                !isBidder
                ?
                (
                    <p className="font-secondary font-bold text-primary text-lg">As a bidder, you must be logged in and admin-approved in order to view the bid history.</p>
                )
                :
                (
                    <>
                        <div style={{maxHeight: "300px"}} className="flex justify-center items-center overflow-y-auto">
                        {
                            !!bidHistory.length && (
                                <BidHistoryTable bidHistory={bidHistory} collapsed={collapsed} bidderAlias={bidderAlias} /> 
                            )
                        }
                            
                        </div>
                        <InputButton 
                        type="button"
                        submitText={collapsed ? 'Show remaining history' : 'Collapse history'}
                        imageTitle={collapsed ? 'add' : 'remove'}
                        imagePosition="left"
                        onClick={(e) => setCollapsed(!collapsed)} 
                        rootClass={`mt-5 mx-auto ${bidHistory.length <= DEFAULT_BIDS ? 'hidden' : ''}`} />
                    </>
                )
            }
            
        </div>
    )
}