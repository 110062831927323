import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import Drawer from '@material-ui/core/Drawer';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import {
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuPopover,
    MenuLink,
  } from "@reach/menu-button";
  import "@reach/menu-button/styles.css";

import Logo from '@static/placeholder-logo.png';

import axios from '@util/axios';

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

export default function Navbar() {
    const location = useLocation();
    const history = useHistory();

    const { addNotification, agencyData } = useContext(AppContext);
    const { userType, userData, setUserData, setUserType, isUserChecked, turnOffInactiveTimer } = useContext(AuthContext);

    const [site, setSite] = useState(location.pathname.startsWith("/agent") ? "agent" : "bidder");
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const handleLogout = (e) => {
        axios.post(`/api/${userType}/logout`) 
        .then((res) => {

            if (!res.data.status) {
                addNotification('There was an issue logging out. Please refresh the page and try again.');
                return ;
            }

            addNotification('You have successfully logged out.');
            
            if (site === "bidder") {
                setUserType(null);
                setUserData(null);
                setMobileMenuOpen(false);
            }
            else {
                setUserType(null);
                setUserData(null);
                setMobileMenuOpen(false);
            }

            turnOffInactiveTimer(); // Make sure to turn of timer for inactivity as we're now logged out.

            if (location.pathname.includes('/dashboard')) {
                history.push('/');
            }
        })
        .catch((err) => {
            console.log(err);
        })
    };

    useEffect(() => {
        if (location.pathname.startsWith("/agent")) {
            if (!site === "agent") {
                setSite("agent");
            }
        }
        else if (site !== "bidder") {
            setSite("bidder");
        }

        setMobileMenuOpen(false);
    }, [location]);

    return (
        <>
            <div className="sticky top-0 z-40 font-medium">
                <div className="flex items-center justify-center 520:justify-end h-8 space-x-3 p-3 520:pr-16 bg-primary-agency text-white text-sm 520:text-base">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 513.64 513.64" fill="white">
                        <path d="M499.66,376.96l-71.68-71.68c-25.6-25.6-69.12-15.359-79.36,17.92c-7.68,23.041-33.28,35.841-56.32,30.72
                            c-51.2-12.8-120.32-79.36-133.12-133.12c-7.68-23.041,7.68-48.641,30.72-56.32c33.28-10.24,43.52-53.76,17.92-79.36l-71.68-71.68
                            c-20.48-17.92-51.2-17.92-69.12,0l-48.64,48.64c-48.64,51.2,5.12,186.88,125.44,307.2c120.32,120.32,256,176.641,307.2,125.44
                            l48.64-48.64C517.581,425.6,517.581,394.88,499.66,376.96z"/> 
                    </svg>
                    <span>{agencyData.branches[0].phone}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512" fill="white">
                        <path d="M480,80H32C14.336,80,0,94.336,0,112v18.208l256,108.416l256-108.448V112C512,94.336,497.664,80,480,80z"/>
                        <path d="M262.24,270.72c-1.984,0.864-4.128,1.28-6.24,1.28s-4.256-0.416-6.24-1.28L0,164.96V400c0,17.664,14.336,32,32,32h448
                            c17.664,0,32-14.336,32-32V164.96L262.24,270.72z"/>
                    </svg>
                    <span>{agencyData.branches[0].email}</span>
                </div>
                <nav className="flex justify-between items-center py-2 px-6 md:px-12 font-primary h-20 bg-white w-full">
                    <div className="flex items-center">
                        <img className="inline-block w-28 max-h-full" src={agencyData.logoAddress} alt="Logo"/>
                        <ul className="hidden xl:flex xl:space-x-5 ml-6">
                            <li>
                                <Link
                                className={`focus:outline-none inline-block ${location.pathname === "/" ? 'border-b-2 border-primary-agency' : 'nav-item-hover'}`}
                                to="/">Home</Link>
                            </li>
                            <li>
                                <Link
                                className={`focus:outline-none inline-block ${location.pathname === "/buying" ? 'border-b-2 border-primary-agency' : 'nav-item-hover'}`}
                                to="/buying">Buying</Link>
                            </li>
                            <li>
                                <Link
                                className={`focus:outline-none inline-block ${location.pathname === "/selling" ? 'border-b-2 border-primary-agency' : 'nav-item-hover'}`}
                                to="/selling">Selling</Link>
                            </li>
                            <li>
                                <Link
                                className={`focus:outline-none inline-block ${location.pathname === "/faq" ? 'border-b-2 border-primary-agency' : 'nav-item-hover'}`}
                                to="/faq">FAQs</Link>
                            </li>
                            <li>
                                <Link
                                className={`focus:outline-none inline-block ${location.pathname === "/contact" ? 'border-b-2 border-primary-agency' : 'nav-item-hover'}`}
                                to="/contact">Contact Us</Link>
                            </li>
                            <li>
                                <a
                                className="focus:outline-none inline-block nav-item-hover"
                                href={agencyData.externalAddress}>Other Services</a>
                            </li>
                        </ul>

                    </div>
                    <div className="hidden xl:flex xl:items-center xl:space-x-5">
                        {
                            isUserChecked ? (
                                userType === null
                                ?
                                (
                                    <>
                                    {
                                        site !== 'agent' && (
                                            <Link className="flex items-center space-x-3" to="/bidder/login">
                                                <svg className="flex-none" width="25" height="25" viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0"/>
                                                </svg>
                                                <span className={`focus:outline-none ${location.pathname === `/bidder/dashboard/watched` ? 'border-b-2 border-primary-agency pointer-events-none' : 'nav-item-hover'}`}>Saved Properties</span>
                                            </Link>
                                        )
                                    }
                                        <Link
                                        className="flex items-center space-x-3"
                                        to={{
                                            pathname: `/${site}/login`,
                                            ...((location.pathname.includes('property') || location.pathname.includes('search')) && {
                                                state: {
                                                    fromUrl: location.pathname
                                                }
                                            })
                                        }}>
                                            <svg className="flex-none" xmlns="http://www.w3.org/2000/svg" 
                                                viewBox="0 0 512 512" width="25" height="25" fill="var(--color-primary-agency)">
                                                    <path d="M256,0C114.841,0,0,114.841,0,256s114.841,256,256,256s256-114.841,256-256S397.159,0,256,0z M346.895,456.06
                                                        c-27.716,12.642-58.497,19.695-90.895,19.695c-32.398,0-63.18-7.053-90.895-19.695v-88.595c0-24.969,20.313-45.281,45.281-45.281
                                                        h91.228c24.968,0,45.281,20.313,45.281,45.281V456.06z M383.14,435.149v-67.684c0-44.953-36.572-81.526-81.526-81.526h-4.533
                                                        c32.021-15.369,54.184-48.113,54.184-85.94c0-52.53-42.736-95.265-95.266-95.265c-52.53,0-95.266,42.736-95.266,95.265
                                                        c0,37.828,22.164,70.571,54.184,85.94h-4.533c-44.953,0-81.526,36.572-81.526,81.526v67.684
                                                        C72.847,395.282,36.245,329.834,36.245,256c0-121.173,98.582-219.755,219.755-219.755S475.755,134.827,475.755,256
                                                        C475.755,329.834,439.153,395.282,383.14,435.149z M256,259.019c-32.544,0-59.021-26.477-59.021-59.021
                                                        c0-32.544,26.477-59.02,59.021-59.02s59.021,26.477,59.021,59.02C315.021,232.544,288.544,259.019,256,259.019z"/>
                                            </svg>
                                            <span className={`focus:outline-none ${location.pathname === `/${site}/login` ? 'border-b-2 border-primary-agency pointer-events-none' : 'nav-item-hover'}`}>Login / Register</span>
                                        </Link>
                                    </>
                                )
                                :
                                (
                                    userType === 'bidder' ? (
                                        <>
                                            { userData && <p className="mr-8 font-primary font-semibold">Signed in as {userData.firstName}</p> }
                                            <Link className="flex items-center space-x-3" to="/bidder/dashboard/watched">
                                                <svg width="25" height="25" viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0"/>
                                                </svg>
                                                <span className="nav-item-hover font-medium">Saved Properties</span>
                                            </Link>
                                            <Menu>
                                                {
                                                    ({ isExpanded }) => {
                                                        if (isExpanded) {
                                                            document.documentElement.style.overflowY = 'hidden';
                                                            document.documentElement.style.paddingRight = '17px';
                                                        }
                                                        else {
                                                            document.documentElement.style.overflowY = null;
                                                            document.documentElement.style.paddingRight = null;
                                                        }

                                                        return (
                                                            <>
                                                                <MenuButton className="font-medium nav-item-hover">My Dashboard</MenuButton>
                                                                <MenuList>
                                                                    <MenuLink as={Link} to="/bidder/dashboard">
                                                                        <div className="flex items-center space-x-3 py-2 pr-8">
                                                                            <svg height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                                                                                <path d="m22.5 10.5c-.276 0-.5-.224-.5-.5 0-2.938-1.144-5.701-3.222-7.778-.195-.195-.195-.512 0-.707s.512-.195.707 0c2.267 2.266 3.515 5.279 3.515 8.485 0 .276-.224.5-.5.5z"/>
                                                                                <path d="m1.5 10.5c-.276 0-.5-.224-.5-.5 0-3.206 1.248-6.219 3.515-8.485.195-.195.512-.195.707 0s.195.512 0 .707c-2.078 2.077-3.222 4.84-3.222 7.778 0 .276-.224.5-.5.5z"/>
                                                                                <path d="m13.5 4.18c-.276 0-.5-.224-.5-.5v-1.68c0-.551-.449-1-1-1s-1 .449-1 1v1.68c0 .276-.224.5-.5.5s-.5-.223-.5-.5v-1.68c0-1.103.897-2 2-2s2 .897 2 2v1.68c0 .277-.224.5-.5.5z"/>
                                                                                <path d="m12 24c-1.93 0-3.5-1.57-3.5-3.5 0-.276.224-.5.5-.5s.5.224.5.5c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5c0-.276.224-.5.5-.5s.5.224.5.5c0 1.93-1.57 3.5-3.5 3.5z"/>
                                                                                <path d="m20.5 21h-17c-.827 0-1.5-.673-1.5-1.5 0-.439.191-.854.525-1.14 1.576-1.332 2.475-3.27 2.475-5.322v-3.038c0-3.86 3.14-7 7-7 3.86 0 7 3.14 7 7v3.038c0 2.053.899 3.99 2.467 5.315.342.293.533.708.533 1.147 0 .827-.672 1.5-1.5 1.5zm-8.5-17c-3.309 0-6 2.691-6 6v3.038c0 2.348-1.028 4.563-2.821 6.079-.115.098-.179.237-.179.383 0 .276.224.5.5.5h17c.276 0 .5-.224.5-.5 0-.146-.064-.285-.175-.38-1.796-1.519-2.825-3.735-2.825-6.082v-3.038c0-3.309-2.691-6-6-6z"/>
                                                                            </svg>
                                                                            <span>Alerts and preferences</span>
                                                                        </div>
                                                                    </MenuLink>
                                                                    <MenuLink as={Link} to="/bidder/dashboard/bids">
                                                                        <div className="flex items-center space-x-3 py-2 pr-8">
                                                                            <svg height="20" viewBox="0 0 512 512" width="20" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                                                                                <path d="m421.625 165.625c0-91.326-74.299-165.625-165.625-165.625s-165.625 74.299-165.625 165.625c0 70.207 43.913 130.344 105.712 154.407v71.468h14.788v120.5h90.25v-120.5h14.788v-71.468c61.799-24.063 105.712-84.2 105.712-154.407zm-150.5 316.375h-30.25v-90.5h30.25zm14.788-120.5h-59.826v-32.963c9.706 1.778 19.702 2.713 29.913 2.713s20.208-.935 29.913-2.713zm-29.913-60.25c-74.784 0-135.625-60.841-135.625-135.625s60.841-135.625 135.625-135.625 135.625 60.841 135.625 135.625-60.841 135.625-135.625 135.625z"/>
                                                                                <path d="m242.049 184.218-41.105-41.103-21.213 21.213 62.318 62.317 90.22-90.22-21.213-21.213z"/>
                                                                            </svg>
                                                                            <span>Property bids</span>
                                                                        </div>
                                                                    </MenuLink>
                                                                    <MenuItem onSelect={handleLogout}>
                                                                        <div className="flex items-center space-x-3 py-2 pr-8">
                                                                            <svg height="20" viewBox="0 0 64 64" width="20" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                                                                                <path d="m56 34c-.512 0-1.023-.195-1.414-.586l-13.436-13.435c-.781-.781-.781-2.047 0-2.828s2.047-.781 2.828 0l13.436 13.435c.781.781.781 2.047 0 2.828-.391.39-.902.586-1.414.586z"/>
                                                                                <path d="m42.564 47.435c-.512 0-1.023-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l13.436-13.435c.781-.781 2.047-.781 2.828 0s.781 2.047 0 2.828l-13.435 13.435c-.391.39-.903.586-1.415.586z"/>
                                                                                <path d="m56 34h-38c-1.104 0-2-.896-2-2s.896-2 2-2h38c1.104 0 2 .896 2 2s-.896 2-2 2z"/>
                                                                                <path d="m10 58c-2.206 0-4-1.794-4-4 0-1.104.896-2 2-2s2 .896 2 2v.003c1.104 0 2 .894 2 1.999s-.896 1.998-2 1.998z"/>
                                                                                <path d="m24 58h-14c-1.104 0-2-.896-2-2s.896-2 2-2h14c1.104 0 2 .896 2 2s-.896 2-2 2z"/>
                                                                                <path d="m8 56c-1.104 0-2-.896-2-2v-44c0-1.104.896-2 2-2s2 .896 2 2v44c0 1.104-.896 2-2 2z"/>
                                                                                <path d="m7.999 12c-1.105 0-1.999-.896-1.999-2 0-2.206 1.794-4 4-4 1.104 0 2 .896 2 2s-.896 2-2 2h-.003c0 1.104-.893 2-1.998 2z"/>
                                                                                <path d="m24 10h-14c-1.104 0-2-.896-2-2s.896-2 2-2h14c1.104 0 2 .896 2 2s-.896 2-2 2z"/>
                                                                            </svg>
                                                                            <span>Sign out</span>
                                                                        </div>
                                                                    </MenuItem> 
                                                                </MenuList>
                                                            </>
                                                        )
                                                    }
                                                }
                                            </Menu>
                                        </>
                                    ) : (
                                        <>
                                            <Link
                                            className="rounded-full bg-primary-agency py-2 px-6 text-white cursor-pointer"
                                            to={`/${userType}/dashboard`}>Dashboard</Link>
                                            <span className="ml-8 nav-item-hover focus:outline-none" onClick={handleLogout}>Logout</span>
                                        </>
                                    )

                                )
                            ) : null
                        }
                
                    </div>
                    <div className="flex items-center xl:hidden cursor-pointer" onClick={(e) => setMobileMenuOpen(true)}>
                        <MenuIcon />
                    </div>
                </nav>
            </div>
            <Drawer anchor="right" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="w-4/5" PaperProps={{className: "w-3/4"}}>
                <nav className="relative p-16 text-xl font-bold">
                { userType === 'bidder' && userData && <p className="xl:mr-8 font-primary font-bold xl:font-semibold text-center">Signed in as {userData.firstName}</p> }

                <ul className="flex flex-col items-center space-y-4 mb-4 mt-12">
                    <li>
                        <Link
                        className={`focus:outline-none inline-block ${location.pathname === "/" ? 'border-b-2 border-primary-agency' : 'nav-item-hover'}`}
                        to="/">Home</Link>
                    </li>
                    <li>
                        <Link
                        className={`focus:outline-none inline-block ${location.pathname === "/buying" ? 'border-b-2 border-primary-agency' : 'nav-item-hover'}`}
                        to="/buying">Buying</Link>
                    </li>
                    <li>
                        <Link
                        className={`focus:outline-none inline-block ${location.pathname === "/selling" ? 'border-b-2 border-primary-agency' : 'nav-item-hover'}`}
                        to="/selling">Selling</Link>
                    </li>
                    <li>
                        <Link
                        className={`focus:outline-none inline-block ${location.pathname === "/faq" ? 'border-b-2 border-primary-agency' : 'nav-item-hover'}`}
                        to="/faq">FAQs</Link>
                    </li>
                    <li>
                        <Link
                        className={`focus:outline-none inline-block ${location.pathname === "/contact" ? 'border-b-2 border-primary-agency' : 'nav-item-hover'}`}
                        to="/contact">Contact Us</Link>
                    </li>
                    <li>
                        <a
                        className="focus:outline-none inline-block nav-item-hover"
                        href={agencyData.externalAddress}>Other Services</a>
                    </li>
                </ul>
                        {
                            isUserChecked ? (
                                userType === null
                                ?
                                (
                                    <>
                                    {
                                        site !== 'agent' && (
                                            <Link className="flex justify-center items-center space-x-3 mt-4" to="/bidder/login">
                                                <svg className="flex-none" width="25" height="25" viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0"/>
                                                </svg>
                                                <span className={`focus:outline-none ${location.pathname === `/bidder/dashboard/watched` ? 'border-b-2 border-primary-agency pointer-events-none' : 'nav-item-hover'}`}>Saved Properties</span>
                                            </Link>
                                        )
                                    }
                                        <Link
                                        className="flex justify-center items-center space-x-3 mt-4"
                                        to={{
                                            pathname: `/${site}/login`,
                                            ...((location.pathname.includes('property') || location.pathname.includes('search')) && {
                                                state: {
                                                    fromUrl: location.pathname
                                                }
                                            })
                                        }}>
                                            <svg className="flex-none" xmlns="http://www.w3.org/2000/svg" 
                                                viewBox="0 0 512 512" width="25" height="25" fill="var(--color-primary-agency)">
                                                    <path d="M256,0C114.841,0,0,114.841,0,256s114.841,256,256,256s256-114.841,256-256S397.159,0,256,0z M346.895,456.06
                                                        c-27.716,12.642-58.497,19.695-90.895,19.695c-32.398,0-63.18-7.053-90.895-19.695v-88.595c0-24.969,20.313-45.281,45.281-45.281
                                                        h91.228c24.968,0,45.281,20.313,45.281,45.281V456.06z M383.14,435.149v-67.684c0-44.953-36.572-81.526-81.526-81.526h-4.533
                                                        c32.021-15.369,54.184-48.113,54.184-85.94c0-52.53-42.736-95.265-95.266-95.265c-52.53,0-95.266,42.736-95.266,95.265
                                                        c0,37.828,22.164,70.571,54.184,85.94h-4.533c-44.953,0-81.526,36.572-81.526,81.526v67.684
                                                        C72.847,395.282,36.245,329.834,36.245,256c0-121.173,98.582-219.755,219.755-219.755S475.755,134.827,475.755,256
                                                        C475.755,329.834,439.153,395.282,383.14,435.149z M256,259.019c-32.544,0-59.021-26.477-59.021-59.021
                                                        c0-32.544,26.477-59.02,59.021-59.02s59.021,26.477,59.021,59.02C315.021,232.544,288.544,259.019,256,259.019z"/>
                                            </svg>
                                            <span className={`focus:outline-none ${location.pathname === `/${site}/login` ? 'border-b-2 border-primary-agency pointer-events-none' : 'nav-item-hover'}`}>Login / Register</span>
                                        </Link>
                                    </>
                                )
                                :
                                (
                                    userType === 'bidder' ? (
                                        <>
                                            <Link className="flex justify-center items-center space-x-3 mt-4" to="/bidder/dashboard/watched">
                                                <svg width="25" height="25" viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0"/>
                                                </svg>
                                                <span className="nav-item-hover">Saved Properties</span>
                                            </Link>
                                            <section className="flex flex-col items-center space-y-4 mt-12">
                                                <header>
                                                    <h2 className="w-fit text-2xl text-center border-b-2 border-b-primary-agency">My Dashboard</h2>
                                                </header>
                                                <Link to="/bidder/dashboard" className="flex items-center space-x-3">
                                                    <svg height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                                                        <path d="m22.5 10.5c-.276 0-.5-.224-.5-.5 0-2.938-1.144-5.701-3.222-7.778-.195-.195-.195-.512 0-.707s.512-.195.707 0c2.267 2.266 3.515 5.279 3.515 8.485 0 .276-.224.5-.5.5z"/>
                                                        <path d="m1.5 10.5c-.276 0-.5-.224-.5-.5 0-3.206 1.248-6.219 3.515-8.485.195-.195.512-.195.707 0s.195.512 0 .707c-2.078 2.077-3.222 4.84-3.222 7.778 0 .276-.224.5-.5.5z"/>
                                                        <path d="m13.5 4.18c-.276 0-.5-.224-.5-.5v-1.68c0-.551-.449-1-1-1s-1 .449-1 1v1.68c0 .276-.224.5-.5.5s-.5-.223-.5-.5v-1.68c0-1.103.897-2 2-2s2 .897 2 2v1.68c0 .277-.224.5-.5.5z"/>
                                                        <path d="m12 24c-1.93 0-3.5-1.57-3.5-3.5 0-.276.224-.5.5-.5s.5.224.5.5c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5c0-.276.224-.5.5-.5s.5.224.5.5c0 1.93-1.57 3.5-3.5 3.5z"/>
                                                        <path d="m20.5 21h-17c-.827 0-1.5-.673-1.5-1.5 0-.439.191-.854.525-1.14 1.576-1.332 2.475-3.27 2.475-5.322v-3.038c0-3.86 3.14-7 7-7 3.86 0 7 3.14 7 7v3.038c0 2.053.899 3.99 2.467 5.315.342.293.533.708.533 1.147 0 .827-.672 1.5-1.5 1.5zm-8.5-17c-3.309 0-6 2.691-6 6v3.038c0 2.348-1.028 4.563-2.821 6.079-.115.098-.179.237-.179.383 0 .276.224.5.5.5h17c.276 0 .5-.224.5-.5 0-.146-.064-.285-.175-.38-1.796-1.519-2.825-3.735-2.825-6.082v-3.038c0-3.309-2.691-6-6-6z"/>
                                                    </svg>
                                                    <span className="nav-item-hover">Alerts and preferences</span>
                                                </Link>
                                                <Link to="/bidder/dashboard/bids" className="flex items-center space-x-3">
                                                    <svg height="20" viewBox="0 0 512 512" width="20" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                                                        <path d="m421.625 165.625c0-91.326-74.299-165.625-165.625-165.625s-165.625 74.299-165.625 165.625c0 70.207 43.913 130.344 105.712 154.407v71.468h14.788v120.5h90.25v-120.5h14.788v-71.468c61.799-24.063 105.712-84.2 105.712-154.407zm-150.5 316.375h-30.25v-90.5h30.25zm14.788-120.5h-59.826v-32.963c9.706 1.778 19.702 2.713 29.913 2.713s20.208-.935 29.913-2.713zm-29.913-60.25c-74.784 0-135.625-60.841-135.625-135.625s60.841-135.625 135.625-135.625 135.625 60.841 135.625 135.625-60.841 135.625-135.625 135.625z"/>
                                                        <path d="m242.049 184.218-41.105-41.103-21.213 21.213 62.318 62.317 90.22-90.22-21.213-21.213z"/>
                                                    </svg>
                                                    <span className="nav-item-hover">Property bids</span>
                                                </Link>
                                                <button onClick={handleLogout} className="flex items-center space-x-3">
                                                    <svg height="20" viewBox="0 0 64 64" width="20" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                                                        <path d="m56 34c-.512 0-1.023-.195-1.414-.586l-13.436-13.435c-.781-.781-.781-2.047 0-2.828s2.047-.781 2.828 0l13.436 13.435c.781.781.781 2.047 0 2.828-.391.39-.902.586-1.414.586z"/>
                                                        <path d="m42.564 47.435c-.512 0-1.023-.195-1.414-.586-.781-.781-.781-2.047 0-2.828l13.436-13.435c.781-.781 2.047-.781 2.828 0s.781 2.047 0 2.828l-13.435 13.435c-.391.39-.903.586-1.415.586z"/>
                                                        <path d="m56 34h-38c-1.104 0-2-.896-2-2s.896-2 2-2h38c1.104 0 2 .896 2 2s-.896 2-2 2z"/>
                                                        <path d="m10 58c-2.206 0-4-1.794-4-4 0-1.104.896-2 2-2s2 .896 2 2v.003c1.104 0 2 .894 2 1.999s-.896 1.998-2 1.998z"/>
                                                        <path d="m24 58h-14c-1.104 0-2-.896-2-2s.896-2 2-2h14c1.104 0 2 .896 2 2s-.896 2-2 2z"/>
                                                        <path d="m8 56c-1.104 0-2-.896-2-2v-44c0-1.104.896-2 2-2s2 .896 2 2v44c0 1.104-.896 2-2 2z"/>
                                                        <path d="m7.999 12c-1.105 0-1.999-.896-1.999-2 0-2.206 1.794-4 4-4 1.104 0 2 .896 2 2s-.896 2-2 2h-.003c0 1.104-.893 2-1.998 2z"/>
                                                        <path d="m24 10h-14c-1.104 0-2-.896-2-2s.896-2 2-2h14c1.104 0 2 .896 2 2s-.896 2-2 2z"/>
                                                    </svg>
                                                    <span className="font-bold nav-item-hover">Sign out</span>
                                                </button>
                                            </section>
                                        </>
                                    ) : (
                                        <>
                                            <Link
                                            className="rounded-full bg-primary-agency py-2 px-6 text-white cursor-pointer"
                                            to={`/${userType}/dashboard`}>Dashboard</Link>
                                            <span className="ml-8 nav-item-hover focus:outline-none" onClick={handleLogout}>Logout</span>
                                        </>
                                    )

                                )
                            ) : null
                        }
                </nav>
        </Drawer>
    </>
    )
}