import React from 'react'
import GoogleMapReact from 'google-map-react'

import { GOOGLE_MAP_API_KEY } from '@util/site-constants'

const Marker = () => {
  return <div className="map-marker-pin -translate-x-1/2 -translate-y-full" />
}

export default function PropertyPageMap({ geoLocation }) {
  const location = geoLocation?.[0] // probobly undefined for properites added ealiar

  if (!location) {
    return <div>No Latitude and Longitude</div>
  }

  const defaultCenter = { lat: Number(location.lat), lng: Number(location.lng) }

  const {lat, lng} = defaultCenter

  if (lat > 90 || lat < -90 || lng > 180 || lng < -180 ) {
    return <div>Invalid coordinates</div>
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
      defaultCenter={defaultCenter}
      defaultZoom={14}
    >
      <Marker lat={defaultCenter.lat} lng={defaultCenter.lng} />
    </GoogleMapReact>
  )
}
