import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import AgentFormImagesUpload from './AgentFormImagesUpload';
import AgentFormDocsUpload from './AgentFormDocsUpload';

function AgentFormImagesDocs({ imgFileUpload, docFileUpload, formData, setFormData, errorMapping, imgSectionError, docSectionError }) {
    return (
        <div className="mt-10">
            <Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ArrowDropDownIcon fontSize="large" color="secondary" />}>
                    <div className="flex items-center text-2xl sm:text-4xl">
                    {
                        imgSectionError && <ErrorIcon className="mr-3" color="error" fontSize="inherit" />
                    }
                        <h1 className="font-primary font-semibold text-white text-xl sm:text-3xl">Property Images</h1>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="w-full sm:p-6 pt-0 pb-10">
                        <AgentFormImagesUpload 
                        formData={formData}
                        errorMapping={errorMapping}
                        {...imgFileUpload}
                        setFormData={setFormData} />
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ArrowDropDownIcon fontSize="large" color="secondary" />}>
                    <div className="flex items-center text-2xl sm:text-4xl">
                    {
                        docSectionError && <ErrorIcon className="mr-3" color="error" fontSize="inherit" />
                    }
                        <h1 className="font-primary font-semibold text-white text-xl sm:text-3xl">Property Documents</h1>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="w-full sm:p-6 pt-0 pb-10">
                        <AgentFormDocsUpload 
                        formData={formData}
                        errorMapping={errorMapping}
                        {...docFileUpload}
                        setFormData={setFormData} />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default React.memo(AgentFormImagesDocs, (prevProps, nextProps) => {
    let formDataEq = JSON.stringify(prevProps.formData) === JSON.stringify(nextProps.formData);
    let errorMappingEq = JSON.stringify(prevProps.errorMapping) === JSON.stringify(nextProps.errorMapping);
    let imgFileUploadEq = JSON.stringify(prevProps.imgFileUpload.imgErrs) === JSON.stringify(nextProps.imgFileUpload.imgErrs);
    let docFileUploadEq = JSON.stringify(prevProps.docFileUpload.imgErrs) === JSON.stringify(nextProps.docFileUpload.imgErrs);

    return formDataEq && errorMappingEq && imgFileUploadEq && docFileUploadEq && prevProps.sectionError === nextProps.sectionError && 
        prevProps.imgSectionError === nextProps.imgSectionError && prevProps.docSectionError === nextProps.docSectionError;
})