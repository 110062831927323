import React, { useContext } from 'react';

import AuthContext from '@contexts/AuthContext';

export default function AgentDashboardHome() {
    const { userData: { email, name, phoneNumber, jobRole, packageType } } = useContext(AuthContext);

    return (
        <main>
            <header className="mb-5">
                <h1 className="font-primary font-bold break-words text-center sm:text-left text-3xl lg:text-4xl xl:text-5xl">Welcome to your Dashboard</h1>
            </header>
            <section className="p-5 pt-3 border-2 border-primary-agency">
                <header>
                    <h2 className="font-semibold text-3xl">Agent Info</h2>
                </header>
                <p className="mt-1 text-gray-500 font-bold">(Please contact support if you wish to update details)</p>
                <div className="space-y-5 mt-6 max-w-2xl">
                    <dl className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
                        <div className="flex flex-1 items-center space-x-3">
                            <dt className="text-primary-agency text-xl">Name:</dt>
                            <dd>{name}</dd>
                        </div>
                        <div className="flex flex-1 items-center space-x-3">
                            <dt className="text-primary-agency text-xl">Email:</dt>
                            <dd>{email}</dd>
                        </div>
                    </dl>
                    <dl className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
                        <div className="flex flex-1 items-center space-x-3">
                            <dt className="text-primary-agency text-xl">Job Role:</dt>
                            <dd>{jobRole}</dd>
                        </div>
                        <div className="flex flex-1 items-center space-x-3">
                            <dt className="text-primary-agency text-xl">Phone Number:</dt>
                            <dd>{phoneNumber}</dd>
                        </div>
                    </dl>
                    <dl className="flex items-center space-x-3">
                        <dt className="text-primary-agency text-xl">Package Type:</dt>
                        <dd>{packageType || 'N/A'}</dd>
                    </dl>
                </div>
            </section>
        </main>
    )

}