import React, { useMemo } from 'react';
import { format } from 'date-fns';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';


export default function PropertyPageBidPriceSection({ bidPrice, bidStartPrice, formattedBidPrice, bidEndTime, reserveStatus, productStatus }) {
    // Section header
    const header = useMemo(() => {
        let header;

        switch (productStatus) {
            case 'Pre-Market':
                header = 'STARTING PRICE';
                break;
            case 'Scheduled Pre-Market':
                header = 'STARTING PRICE';
                break;
            case 'Live': 
                header = bidStartPrice !== bidPrice ? 'CURRENT BID' : 'STARTING PRICE';
                break;
            case 'Sold':
                header = 'SOLD PRICE';
                break;
            case 'Ended':
                header = 'END PRICE';
                break;
            default:
                break;
        }

        return header;
    }, [productStatus, bidStartPrice, bidPrice]);

    return (
        <section className="flex flex-col justify-center items-center p-3 bg-light-gray">
            <header>
                <h2 className="font-semibold">{header}</h2>
            </header>
            <p className="font-bold text-5xl my-3">£{formattedBidPrice}</p>
            {
                productStatus !== 'Pre-Market' && productStatus !== 'Scheduled Pre-Market' && (
                    <>
                        <p className="text-sm font-semibold">END DATE: {format(bidEndTime, 'dd/MM/yyyy HH:mm')}</p>
                        <div className="flex items-center font-bold uppercase mt-2">
                        {
                            reserveStatus !== 'No Reserve' ? (
                                reserveStatus === 'Reserve Met' ? (
                                    <>
                                        <span className="text-green-600 mr-2"><DoneIcon color="inherit" /></span>Reserve Met
                                    </>
                                ) : (
                                    <>
                                        <span className="text-air-red mr-2"><ClearIcon color="inherit" /></span>Reserve Not Met
                                    </>
                                )
                            ) : (
                                <>No Reserve Set on Property</>
                            )
                        }
                        </div>
                    </>
                )
            }
        </section>
    )
}