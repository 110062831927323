import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';

import axios from '@util/axios';
import ValidationTypes from '@util/ValidationTypes';

import useForm from '@hooks/useForm';

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

import InputButton from '@components/forms/InputButton';
import FormInput from '@components/forms/FormInput';

export default function BidderLogin() {
    const history = useHistory();
    const { addNotification } = useContext(AppContext);
    const { setUserType, setUserData } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    /* 
        Effect:

        When the user is on the verified variant of the login page, display extra context.
    */
    useEffect(() => {
        if (location.search === "?verified=true") {
            addNotification("You have now verified your email", <span>You should now login, go to your dashboard, and complete registration
                in order to bid on properties.
            </span>);
        }
    }, []);

    const loginFormRules = {
        email: [ValidationTypes.requiredString, ValidationTypes.validEmail],
        password: [ValidationTypes.requiredString]
    };

    const { formData, handleChange, handleSubmit, errorMapping } = useForm({
        email: '',
        password: ''
    }, loginFormRules);

    const [loginError, setLoginError] = useState("");

    const submitSuccessHandler = () => {
        setLoading(true);
        axios.post('/api/bidder/login', formData)
        .then((res) => {
            setLoading(false);
            if (!res.data.status) {
                setLoginError(res.data.message);
            }
            else {
                let { myBids, isVerified } = res.data.data;
                
                if (!isVerified) {
                    history.push('/bidder/not-verified', {
                        email: formData.email
                    });
                }
                else {
                    let bidsMaxPrice = {};

                    if (myBids.length) {
                        // socketController.initializeSocket();
                        let bidProductSet = new Set();
    
                        myBids.forEach((bid) => {
                            if (bidsMaxPrice[bid.productId]) {
                                if (bid.price > bidsMaxPrice[bid.productId]) {
                                    bidsMaxPrice[bid.productId] = bid.price;
                                }    
                            }
                            else {
                                bidsMaxPrice[bid.productId] = bid.price;
                            }
    
                            bidProductSet.add(bid.productId);
                        });
                    }

                    setUserType('bidder');
                    setUserData({
                        ...res.data.data,
                        isFullyRegistered: !!(res.data.data.isVerified && res.data.data.isApproved.length),
                        bidsMaxPrice });
                        
                    // if (location.state) {

                    //     if (location.state.from) {
                    //         history.push(location.state.from.pathname);
                    //     }
                    //     else if (location.state.fromUrl) {
                    //         history.push(location.state.fromUrl);
                    //     }
                    // }
                    // else {
                    //     history.push('/');
                    // }
                    history.goBack();
                }
                
            }
        })
        .catch((err) => { throw err; });
    };

    return (
        <main>
            <div className="fixed register-bg bg-center bg-fixed top-0 left-0 right-0 bottom-0 -z-1" />
            <div className="w-full flex justify-center pt-24">
                <form
                onSubmit={(e) => handleSubmit(e, submitSuccessHandler)}
                className="w-5/6 sm:w-1/2 lg:w-1/3 min-w-mobile pb-5 sm:pb-10 rounded-lg bg-white bg-opacity-90">
                    <div className="rounded-lg rounded-b-none bg-primary-agency text-center py-5 px-5 sm:px-10">
                        <h1 className="font-primary font-semibold text-3xl text-white">Bidder Login</h1>
                        <p className="mt-2 text-secondary text-sm font-secondary">
                            Login to access your dashboard and view more property information.
                        </p>
                    </div>
                    <div className="px-5 sm:px-10 mt-5">
                        <div className="relative">
                            <FormInput 
                            type="text"
                            label="Email"
                            placeholder="Enter your email"
                            inputName="email"
                            value={formData.email}
                            error={errorMapping.email}
                            handleChange={handleChange}
                            />
                            <div className="mt-8">
                                <FormInput 
                                type="password"
                                label="Password"
                                placeholder="Enter your password"
                                inputName="password"
                                value={formData.password}
                                error={errorMapping.password}
                                handleChange={handleChange}
                                />
                            </div>
                        {
                            loginError &&
                            (
                                <div className="absolute w-full flex justify-center mt-4">
                                    <span className="font-primary font-black text-red-500">{loginError}</span>
                                </div>
                            )
                        }
                        </div>
                        <p className=" mt-12 text-center">Don't have an account? <Link to="/bidder/register" className="font-black text-primary underline">Sign up here.</Link></p>
                        <Link to="/bidder/forgot-password" className="block mt-2 font-black text-primary underline cursor-pointer text-center">
                            Forgot my password
                        </Link>
                        <InputButton
                        type="submit"
                        submitText="Login"
                        rootClass="mx-auto mt-5" 
                        loading={loading} />
                    </div>
                </form>
            </div>
            
        </main>
    )
}