import React, {useState, useEffect } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import Switch from '@material-ui/core/Switch'

import * as ga from '@util/ga'
import cookie from '@util/cookie'

const COOKIE_SETTING_FLAG = 'cookie-setting-done'
const COOKIE_SETTING_PERFORMANCE = 'cookie-setting-performance'

export default function CookieConsentBar() {
  const [open, setOpen] = useState(false)
  const [settingOpen, setSettingOpen] = useState(false)
  const [performanceCookieChecked, setPerformanceCookieChecked] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleSettingOpen = () => {
    setSettingOpen(true)
  }

  const handleSettingClose = () => {
    setSettingOpen(false)
  }

  const applayPerformanceCookies = () => {
    ga.init()
    ga.pageview(window.location.pathname, window.location.search)
    cookie.set(COOKIE_SETTING_PERFORMANCE, 'true')
  }

  const cookieSetted = () => {
    cookie.set(COOKIE_SETTING_FLAG, 'true')
  }

  const handleAcceptAll = () => {
    // apply performance cookies
    applayPerformanceCookies()
    handleSettingClose()
    handleClose()
    cookieSetted()
  }

  const handleConfirm = () => {
    if (performanceCookieChecked) {
      applayPerformanceCookies()
    }
    handleSettingClose()
    handleClose()
    cookieSetted()
  }

  useEffect(() => {
    if (cookie.has(COOKIE_SETTING_FLAG)) {
      if (cookie.has(COOKIE_SETTING_PERFORMANCE)) {
        applayPerformanceCookies()
      }
      return
    }
    if (window.navigator.userAgent === 'ReactSnap') {
      return
    }
    setTimeout(() => {
      setOpen(true)
    }, 2000)
  }, [])

  return (
    <div>
      <Dialog open={open} >
        <DialogContent>
          <div className="text-black">
            By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
          </div>
        </DialogContent>
        <DialogActions>
          <button className="px-5 py-3 rounded-md text-secondary-agency underline" onClick={handleSettingOpen}>Cookies Settings</button>
          <button className="px-5 py-3 bg-secondary-agency text-white rounded-md" onClick={handleAcceptAll}>Accept All Cookies</button>
        </DialogActions>
      </Dialog>
      <Drawer anchor="left" open={settingOpen} onClose={handleSettingClose} >
        <div className="flex flex-col h-full w-120">
          <header className="border-b px-4 py-3 flex justify-between items-center">
            <h2 className="text-black text-lg">Cookie Settings</h2>
            <CloseIcon className="cursor-pointer" onClick={handleSettingClose} />
          </header>
          <div className="flex-grow p-4">
            <div>
              <p className="mb-8">When you visit our website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and manage your preferences. Please note, blocking some types of cookies may impact your experience of the site and the services we are able to offer.</p>
              <ul className="border">
                <li className="flex items-center px-3 py-3">
                  <p className="flex-1">Strictly Necessary Cookies</p>
                  <Switch defaultChecked disabled color="primary" />
                </li>
                <li className="flex items-center px-3 py-3 border-t">
                  <p className="flex-1">Performance Cookies</p>
                  <Switch color="primary" checked={performanceCookieChecked} onChange={e => setPerformanceCookieChecked(e.target.checked)}/>
                </li>
              </ul>
            </div>
          </div>
          <footer className="border-t py-6 flex justify-evenly items-center">
            <button className="px-3 w-2/5 text-xs py-3 bg-secondary-agency text-white rounded-md" onClick={handleConfirm}>Confirm my choices</button>
            <button className="px-3 w-2/5 text-xs py-3 box-border text-secondary-agency border border-secondary-agency rounded-md" onClick={handleAcceptAll}>Accept all cookies</button>
          </footer>
        </div>
      </Drawer>
    </div>
  )
}
