import React, { useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ErrorIcon from '@material-ui/icons/Error';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import SelectFields from '@util/SelectFields';

import FormInput from '@components/forms/FormInput';
import Select from '@components/forms/Select';

const getStatusOptions = (currentStatus) => {
    switch (currentStatus) {
        case 'Draft':
            return [{
                name: 'Draft',
                value: 'Draft'
            },    
            {
                name: 'Pre-Market',
                value: 'PreMarket'
            },
            {
                name: 'Scheduled',
                value: 'Scheduled'
            },
            {
                name: 'Live',
                value: 'Live'
            }];
        case 'PreMarket':
            return [{
                name: 'Draft',
                value: 'Draft'
            },
            {
                name: 'Pre-Market',
                value: 'PreMarket'
            },
            {
                name: 'Scheduled',
                value: 'Scheduled'
            },
            {
                name: 'Live',
                value: 'Live'
            },
            {
                name: 'Withdrawn',
                value: 'Withdrawn'
            }];
        case 'Scheduled':
            return [{
                name: 'Draft',
                value: 'Draft'
            },
            {
                name: 'Pre-Market',
                value: 'PreMarket'
            },
            {
                name: 'Scheduled',
                value: 'Scheduled'
            },
            {
                name: 'Live',
                value: 'Live'
            },
            {
                name: 'Withdrawn',
                value: 'Withdrawn'
            }];
        case 'ScheduledPreMarket':
            return [{
                name: 'Scheduled',
                value: 'Scheduled'
            },
            {
                name: 'Live',
                value: 'Live'
            },
            {
                name: 'Withdrawn',
                value: 'Withdrawn'
            }];
        default:
            break;
    }
}

// TODO Make sure to add guide price and round to 100 whenever blurred (i.e. focus moved)
export default function AgentFormAuction({ mode, status, formData, setFormData, handleChange, errorMapping, setErrorMapping, sectionError }) {
    const [statusOpen, setStatusOpen] = useState(false);
    
    /**
     * Rounds guide price to the nearest 100.
     */
    const handleRoundGuidePrice = (e) => {
        if (formData.guidePrice) {
            setFormData((oldFormData) => ({ ...oldFormData, guidePrice: Math.round(oldFormData.guidePrice / 100) * 100}));
        }
    }

    return (
        <div className="mt-10 w-full">
            <Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ArrowDropDownIcon fontSize="large" color="secondary" />}>   
                    <div className="flex items-center text-4xl">
                    {
                        sectionError && (
                            <ErrorIcon className="mr-3" color="error" fontSize="inherit" />
                        )
                    }
                        <h1 className="font-primary font-semibold text-white text-xl sm:text-3xl">Auction Information</h1>
                    </div>                 
                </AccordionSummary>
                <AccordionDetails>
                    <div className="w-full flex flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-6 py-3 sm:p-3 md:p-8 md:pb-10">
                        <div className="w-full">
                            <div className="flex items-center">
                                <span className="material-icons mr-8 text-5xl">payments</span>
                                <FormInput 
                                type="number"
                                label="Reserve Price"
                                inputName="reservePrice"
                                value={formData.reservePrice}
                                error={errorMapping.reservePrice}
                                handleChange={handleChange} 
                                helperText="Put 0 if you are not using a reserve price."/>
                            </div>
                            <FormInput 
                            type="number"
                            label="Bid Start Price"
                            inputName="bidStartPrice"
                            value={formData.bidStartPrice}
                            error={errorMapping.bidStartPrice}
                            handleChange={handleChange}
                            rootClass="pl-20 mt-8" />
                            <FormInput 
                            type="number"
                            label="Guide Price"
                            inputName="guidePrice" 
                            value={formData.guidePrice} 
                            error={errorMapping.guidePrice} 
                            handleChange={handleChange}
                            inputProps={{
                                onBlur: handleRoundGuidePrice
                            }}
                            helperText="Leave empty if you are not using a guide price. The guide price will otherwise be rounded to the nearest 100." 
                            rootClass="pl-20 mt-8" />
                        </div>
                        <div className="w-full">
                            <div className="flex">
                                <span className="material-icons mr-8 text-5xl">timeline</span>
                                <div className="flex-grow">
                                    <div className="relative">
                                        <Select
                                        label="Product Status"
                                        name="productStatus"
                                        value={formData.productStatus}
                                        options={mode === 'add' ? [{
                                            name: "Draft",
                                            value: "Draft"
                                        },
                                        {
                                            name: "Pre-Market",
                                            value: "PreMarket"
                                        },
                                        {
                                            name: "Scheduled",
                                            value: "Scheduled"
                                        },
                                        {
                                            name: "Live",
                                            value: "Live"
                                        }] : getStatusOptions(status)}
                                        onChange={handleChange}
                                        error={errorMapping.productStatus} />
                                    {
                                        statusOpen
                                        ?
                                        (
                                            <CancelIcon className="absolute top-0 right-0 mr-3 cursor-pointer" onClick={(e) => setStatusOpen(false) }/>
                                        )
                                        :
                                        (
                                            <HelpIcon className="absolute top-0 right-0 mr-3 cursor-pointer" onClick={(e) => setStatusOpen(true) }/>
                                        )
                                    }
                                    <div className={`helper-text z-20 bottom-full right-0 mr-2 w-64 ${statusOpen ? 'opacity-100' : ''} pointer-events-none`}>
                                        <h2 className="text-xl text-center">Status Explanations</h2>
                                        <ul className="mt-5 list-disc ml-3 space-y-3">
                                            <li>Draft: <span className="font-normal text-sm">Product is neither visible to bidders nor scheduled to go live.</span></li>
                                            <li>Pre-Market: <span className="font-normal text-sm">Product is visible to bidders, but cannot be bid on.</span></li>
                                            <li>Scheduled: <span className="font-normal text-sm">Product is scheduled to go live at a specific time.</span></li>
                                            <li>Live: <span className="font-normal text-sm">Product is live and no more manual edits can be made.</span></li>
                                            <li>Withdrawn: <span className="font-formal text-sm">Product is withdrawn and can no longer be edited/viewed.</span></li>
                                        </ul>
                                    </div>
                                    </div>
                                    {
                                        formData.productStatus === "Withdrawn" &&
                                        (
                                            <p className="mt-5 font-secondary font-bold text-error-red text-lg"> 
                                                Warning: <span className="font-normal">Once a product is withdrawn, it cannot be edited, and it cannot be viewed by a bidder.</span>
                                            </p>
                                        )
                                    }
                                    
                                    {
                                        formData.productStatus === "Scheduled" &&
                                        (
                                            <div className="mt-5">
                                                <input 
                                                className="mr-3"
                                                id="checkPreMarket" 
                                                type="checkbox"
                                                name="checkPreMarket"
                                                onChange={handleChange}
                                                checked={formData.checkPreMarket} />
                                                <label className="form-input-label text-base" htmlFor="checkPreMarket">Pre-Market (Show property to bidder before live)</label>
                                            </div>
                                        )

                                    }
                                </div>
                            </div>
                            {
                                (formData.productStatus === "Scheduled" || formData.productStatus === "Live") &&
                                (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="flex mt-8">
                                            <span className="material-icons mr-8 mt-3 text-5xl">event</span>
                                            <div className="flex-grow">
                                                {
                                                    formData.productStatus === "Scheduled" &&
                                                    (
                                                        <fieldset>
                                                                <legend className="form-input-label">Scheduled Start Time</legend>
                                                                <div className="flex items-center space-x-5">
                                                                    <div>
                                                                        <label htmlFor="publishDate" className="form-input-label">Date</label>
                                                                        <KeyboardDatePicker 
                                                                        autoOk
                                                                        variant="inline"
                                                                        minDate={new Date()}
                                                                        minDateMessage={""}
                                                                        value={formData.publishDate}
                                                                        onChange={(date) => {
                                                                            if (date) {
                                                                                if (isNaN(date.getTime())) {
                                                                                    setErrorMapping((errorMapping) => { return {...errorMapping, publishDate: "Invalid date format"}})
                                                                                }
                                                                                else {
                                                                                    if (errorMapping.publishDate === "Invalid date format") {
                                                                                        setErrorMapping((errorMapping) => { return {...errorMapping, publishDate: "" } });
                                                                                        setFormData((prevFormData) => { 
                                                                                            date.setHours(prevFormData.publishDate.getHours(), prevFormData.publishDate.getMinutes());
                                                                                            return {...prevFormData, publishDate: date} 
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        setFormData((prevFormData) => { 
                                                                                            date.setHours(prevFormData.publishDate.getHours(), prevFormData.publishDate.getMinutes());
                                                                                            return {...prevFormData, publishDate: date} 
                                                                                        })
                                                                                    }
                                                                                }
                                                                            }
                                                                        }}
                                                                        format="MM/dd/yyyy" 
                                                                        disablePast 
                                                                        className="w-full font-primary"
                                                                        id="publishDate" />
                                                                    </div>
                                                                    <div className="flex items-center w-full">
                                                                        <Select 
                                                                        label="Hour"
                                                                        name="publishDateHour"
                                                                        value={formData.publishDateHour} 
                                                                        options={SelectFields.hours}
                                                                        onChange={(e) => setFormData((formData) => {
                                                                            let newFormData = {...formData};
                                                                            
                                                                            newFormData.publishDate.setHours(parseInt(e.target.value));
                                                                            return newFormData;
                                                                        })} />
                                                                        <span className="ml-2 mr-2 text-xl self-end mb-2"> : </span>
                                                                        <Select 
                                                                        label="Minute"
                                                                        name="publishDateMinute"
                                                                        value={formData.publishDateMinute}
                                                                        options={SelectFields.minutes}
                                                                        onChange={(e) => setFormData((formData) => {
                                                                            let newFormData = {...formData};
                                                                            
                                                                            newFormData.publishDate.setMinutes(parseInt(e.target.value));
                                                                            return newFormData;
                                                                        })} />
                                                                    </div>
                                                                </div>
                                                                <div className="relative">
                                                                    {errorMapping.publishDate && <span className="form-error-text absolute mt-1">{errorMapping.publishDate}</span>}
                                                                </div>
                                                            </fieldset>
                                                    )
                                                }
                                                {
                                                    (formData.productStatus === "Scheduled" || formData.productStatus === "Live") &&
                                                    (
                                                            <fieldset className={formData.productStatus === "Scheduled" ? "mt-8" : ""}>
                                                                <legend className="form-input-label">Bid End Time</legend>
                                                                <div className="flex items-center space-x-5">
                                                                    <div>
                                                                        <label htmlFor="bidEndDate" className="form-input-label">Date</label>
                                                                        <KeyboardDatePicker 
                                                                        autoOk
                                                                        variant="inline"
                                                                        minDate={new Date()}
                                                                        minDateMessage={""}
                                                                        value={formData.bidEndTime}
                                                                        onChange={(date) => {
                                                                            if (date) {
                                                                                if (isNaN(date.getTime())) {
                                                                                    setErrorMapping((errorMapping) => { return {...errorMapping, bidEndTime: "Invalid date format"}})
                                                                                }
                                                                                else {
                                                                                    if (errorMapping.bidEndTime === "Invalid date format") {
                                                                                        setErrorMapping((errorMapping) => { return {...errorMapping, bidEndTime: "" } });
                                                                                        setFormData((prevFormData) => { 
                                                                                            date.setHours(prevFormData.bidEndTime.getHours(), prevFormData.bidEndTime.getMinutes());
                                                                                            return {...prevFormData, bidEndTime: date} 
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        setFormData((prevFormData) => { 
                                                                                            date.setHours(prevFormData.bidEndTime.getHours(), prevFormData.bidEndTime.getMinutes());
                                                                                            return {...prevFormData, bidEndTime: date} 
                                                                                        })
                                                                                    }
                                                                                }
                                                                            }
                                                                        }}
                                                                        format="MM/dd/yyyy" 
                                                                        disablePast 
                                                                        className="w-full font-primary"
                                                                        id="bidEndDate" />
                                                                    </div>
                                                                    <div className="flex items-center w-full">
                                                                        <Select 
                                                                        label="Hour"
                                                                        name="bidEndTimeHour"
                                                                        value={formData.bidEndTimeHour} 
                                                                        options={SelectFields.hours}
                                                                        onChange={(e) => setFormData((formData) => {
                                                                            let newFormData = {...formData};
                                                                            
                                                                            newFormData.bidEndTime.setHours(parseInt(e.target.value));
                                                                            return newFormData;
                                                                        })} />
                                                                        <span className="ml-2 mr-2 text-xl self-end mb-2"> : </span>
                                                                        <Select 
                                                                        label="Minute"
                                                                        name="bidEndTimeMinute"
                                                                        value={formData.bidEndTimeMinute}
                                                                        options={SelectFields.minutes}
                                                                        onChange={(e) => setFormData((formData) => {
                                                                            let newFormData = {...formData};
                                                                            
                                                                            newFormData.bidEndTime.setMinutes(parseInt(e.target.value));
                                                                            return newFormData;
                                                                        })} />
                                                                    </div>
                                                                </div>
                                                                <div className="relative">
                                                                    {errorMapping.bidEndTime && <span className="form-error-text absolute mt-1">{errorMapping.bidEndTime}</span>}
                                                                </div>
                                                            </fieldset>


                                                    ) 
                                                }
                                                
                                            </div>

                                        </div>
                                    </MuiPickersUtilsProvider>
                                )
                            }
                        </div>
                        
                    
                    </div>
                </AccordionDetails>
            </Accordion>
                
                
        </div>
    )
}