import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import axios from '@util/axios';

import useLoading from '@hooks/useLoading';

import InputButton from '@components/forms/InputButton';

export default function AgentDashboardPropertyDelete({ open, handleClose, handleDelete, propertyId }) {
    const { loading, setLoading, loaded, setLoaded } = useLoading();
    const [deleteError, setDeleteError] = useState("");

    const handleDeleteClick = (e) => {

        setLoading(true);
        axios.post('/api/agency/product/delete', {
            productId: propertyId
        })
        .then((res) => {

            if (res.data.status) {
                handleClose();
                setLoading(false);
                setLoaded(true);
                // HACK - added timeout b/c updating the table from handleDelete caused modal to close abruptly
                setTimeout(() => {
                    handleDelete(propertyId);
                }, 100);
                console.log(res.data);
            }
            else {
                return Promise.reject(res.data.message);
            }
        })
        .catch((err) => setDeleteError(err));
    }

    return (
        <Modal
        className="flex justify-center items-center"
        open={open}
        closeAfterTransition
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
            appear: true,
            enter: 500,
            exit: 750
        }}
        >
            <Fade in={open}>
                <div className="outline-none rounded-lg shadow-lg bg-white w-1/3">
                    <h1 className="rounded-header text-center">Delete Property</h1>
                    <div className="py-5 px-10">
                        {
                            deleteError
                            ?
                            (
                                <div>
                                    <p className="font-secondary font-bold text-lg text-center">There was an error deleting your property:</p>
                                    <p className="font-secondary font-bold text-xl text-air-red text-center my-5">{deleteError}</p>
                                    <p className="font-secondary font-bold text-lg text-center">Please contact us at email@email.com for any questions/concerns or try again.</p>
                                    <InputButton 
                                    type="button"
                                    submitText="Close"
                                    rootClass="mx-auto mt-8"
                                    onClick={handleClose} />
                                </div>
                            )
                            :
                            (
                                <>
                                    <p className="font-secondary font-bold text-xl text-center">Are you sure you want to delete this property?</p>
                                    <div className="flex justify-center mt-5">
                                        {
                                            !loaded &&
                                            (
                                                <>
                                                    <InputButton 
                                                    type="button"
                                                    submitText={loading ? 'Deleting' : 'Yes'}
                                                    imageTitle={loading && 'loading'}
                                                    inputStyle="inverse" 
                                                    rootClass="px-10"
                                                    onClick={handleDeleteClick} />
                                                    {
                                                        !loading && !loaded && (
                                                            <InputButton 
                                                            type="button"
                                                            submitText="No"
                                                            rootClass="ml-5 px-10"
                                                            onClick={handleClose} />
                                                        )
                                                    }
                                                </>
                                            )
                                        }


                                    </div>
                                </>
                            )
                        }
                        
                    </div>
                </div>
            </Fade>
        </Modal>
    )
};