import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import VisibilityIcon from '@material-ui/icons/Visibility';

export default function BidderDashboardNavbarNew() {
    const location = useLocation();
    const navItems = {
        home: (
            <>
                <HomeIcon />
                <h2 className="hidden sm:block">Home</h2>
            </>
        ),
        watched: (
            <>
                <VisibilityIcon />
                <h2 className="hidden sm:block">My Saved Properties</h2>
            </>
        ),
        bids: (
            <>
                <span className="material-icons inline-block">history_edu</span>
                <h2 className="hidden sm:block">Property Bids</h2>
            </>
        )

    }
    return (
        <nav className="fixed left-0 z-10 bottom-0 w-full sm:w-auto sm:relative sm:mt-8 sm:mx-8 md:mx-10 lg:mx-12">
            <ul className="flex items-center sm:space-x-5">
            {
                Object.keys(navItems).map((path) => (
                    <li className="w-full sm:w-auto" key={path}>
                        <Link 
                        to={`/bidder/dashboard/${path}`} 
                        className={`flex flex-col sm:flex-row items-center sm:space-x-3 py-2 px-4 transition-colors duration-200 ease-in-out font-semibold text-xs sm-480:text-base 
                        ${location.pathname === `/bidder/dashboard/${path}` ? 
                            'bg-primary-agency text-white border-2 border-primary-agency': 
                            'bg-white text-primary-agency border-2 border-primary-agency hover:bg-primary-agency hover:text-white'}`
                        }>
                            {navItems[path]}
                        </Link>
                    </li>
                ))
            }
            </ul>
        </nav>
    )
}