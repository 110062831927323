import openSocket from 'socket.io-client'; 
import { API_DOMAIN } from './site-constants';

let socket;

const initializeSocket = () => {
    socket = openSocket(API_DOMAIN);
};

const emitEvent = (event, data) => socket.emit(event, data);

const addEventListener = (event, cb) => socket.on(event, cb);

const removeEventListener = (event, cb) => socket.off(event, cb);

const disconnect = () => socket.disconnect();

const isConnected = () => socket && socket.connected;

export default { isConnected, initializeSocket, emitEvent, addEventListener, removeEventListener, disconnect };