import React, { useContext, useState } from 'react';
import EmailIcon from '@material-ui/icons/Email';

import { bidder } from '@api/api';

import AppContext from '@contexts/AppContext';

import InputButton from '@components/forms/InputButton';


export default function BidderVerifyEmail({ history }) {
    const { addNotification, agencyData: { agencyId } } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(null);

    const handleResendLink = (e) => {
        if (!loading) {
            setLoading(true);
            bidder.post({
                endpoint: 'resendVerifyEmail',
                data: {
                    email: history.location.state.email,
                    agencyId
                },
                handleSuccess: (data) => { console.log(data); addNotification('Email verification link resent!') },
                handleFail: (err) => setErr(err),
                handleComplete: () => setLoading(false)
            });
        }
    }

    return (
        <div className="register-bg flex justify-center items-center h-screen bg-center bg-fixed">
            <div className="w-1/3 min-w-mobile flex flex-col justify-center bg-white rounded-lg bg-opacity-90">
                <div className="rounded-header flex items-center">
                    <EmailIcon fontSize="large" />
                    <h1 className="ml-5">Verification Email Sent</h1>
                </div>
                <div className="p-6">
                    <p className="font-secondary font-bold text-lg mb-5">A link to verify your email has been sent to {history.location.state && history.location.state.email}. 
                    Please check your junk/spam folders as well.</p>
                    <p className="font-secondary font-semibold text-sm text-primary mb-5">Don't see the email? Click the button below to resend the verification link. 
                    (Once you leave this page, you can still resend the link once you log in again.) Otherwise, you also choose to get redirected back to the home page. </p>
                    {
                        err && (<p className="form-error-text my-3">{err}</p>)
                    }
                    <div className="flex justify-center">
                        <InputButton 
                        submitText="Resend Verification Link"
                        rounded="lg" 
                        onClick={handleResendLink}
                        loading={loading} />
                        <InputButton 
                        submitText="Take Me Home"
                        rounded="lg"
                        rootClass="ml-5" 
                        onClick={(e) => history.push('/')} 
                        disabled={loading} />
                    </div>
                </div>

            </div>
        </div>
    )
}