import React, { useState, createContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

import useNotifications from '@hooks/useNotifications';

import axios from '@util/axios';
import { useLayoutEffect } from 'react';

const stripePromise = loadStripe("pk_test_Qc3GUbFkNFlJoF0xJrPDAWfl00RTmcKHi9");

const AppContext = createContext();

/**
 * The context provider for global app state.
 * @state serverError - Error from API call (500 status)
 */
export const AppContextProvider = ({ children }) => {
    const history = useHistory();
    const notificationsHook = useNotifications();
    const [serverError, setServerError] = useState(null);
    const [agencyData, setAgencyData] = useState(null);

    // TODO Update such that it no longer redirects, but rather displays the error on the same page (doesn't make sense to go to an error page)
    /* 
        Effect:
        Whenever there's a server error, redirect to 
        error page with error message.
    */
    useEffect(() => {
        if (serverError) {
            history.push('/error', {
                errorContext: serverError
            })
        }
    }, [serverError]);

    /* 
        Effect:
        Get agency data on mount, from both endpoints (one for logo address)
    */
    useEffect(() => {
        (async () => {
            try {
                const res = await axios.post('/api/agency/getAgencyDetailsByDomain');
                setAgencyData(res.data.data);
            }
            catch (e) {
                setServerError('Error accessing agency details.');
            }
        })();
    }, []);

    /* 
        Effect:
        Updates colors based on agency data. Uses useLayoutEffect to prevent
        style flickering (will happen before the commits to new render.)
    */
    useLayoutEffect(() => {
        if (agencyData) {
            const { primaryColourH, primaryColourS, primaryColourL, secondaryColourH, secondaryColourS, secondaryColourL, primaryColourLOffset } = agencyData;
            document.documentElement.style.setProperty('--color-primary-agency', `hsl(${primaryColourH}, ${primaryColourS}%, ${primaryColourL}%)`);
            document.documentElement.style.setProperty('--color-primary-agency-opaque', `hsla(${primaryColourH}, ${primaryColourS}%, ${primaryColourL}%, 0.25)`);
            document.documentElement.style.setProperty('--color-primary-agency-light', `hsla(${primaryColourH}, ${primaryColourS}%, ${primaryColourL}%, 0.75)`);
            document.documentElement.style.setProperty('--color-primary-agency-dark', `hsl(${primaryColourH}, ${primaryColourS}%, ${primaryColourL - primaryColourLOffset}%)`);
            document.documentElement.style.setProperty('--color-secondary-agency', `hsl(${secondaryColourH}, ${secondaryColourS}%, ${secondaryColourL}%)`);
        }
    }, [agencyData]);
    
    return (
        <AppContext.Provider value={{
            ...notificationsHook,
            serverError,
            setServerError,
            stripePromise,
            agencyData
        }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;

