/* 
    Order of imports:
    1. Third-party libraries (i.e. react, material-ui, etc.)
    2. Utils
    3. Hooks
    4. Contexts
    5. Components
*/
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { AppContextProvider } from '@contexts/AppContext';
import { AuthContextProvider } from '@contexts/AuthContext';

import theme from '@components/materialui/theme';
import MainRouter from '@components/routing/MainRouter';
import CookieConsentBar from '@components/cookies/CookieConsentBar';

// TODO Update all promise-based logic to more modern async/await!
function App() {
    // const notificationsHook = useNotifications();
    // // Set bidder data initially to prevent having to wait for the request to finish in order to see
    // // stuff like Dashboard Logout instead of Login Register.
    // const [bidderData, setBidderData] = useState(() => {
    //     let bidderData = localStorage.getItem("bidderData");
    //     if (bidderData) {
    //         return JSON.parse(bidderData);
    //     }
    //     else {
    //         return {};
    //     }
    // });

    /* 
        Effect: Initialize animate-on-scroll.
    */
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <AppContextProvider>
                    <AuthContextProvider>
                        <MainRouter />
                        <CookieConsentBar />
                    </AuthContextProvider>
                </AppContextProvider>
            </Router>
        </ThemeProvider>
    );
}

export default App;
