import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { format, parseISO } from 'date-fns';

const DEFAULT_BIDS = 4;

export default function BidHistoryTable({ bidderAlias, bidHistory, collapsed }) {
    const tableColumns = useMemo(() => [
        {
            Header: 'Amount',
            accessor: 'price'
        },
        {
            Header: <span className="w-48">&nbsp;</span>,
            accessor: 'isBidder',
        },
        {
            Header: 'Time',
            accessor: 'time'
        },

    ], []);

    const tableData = useMemo(() => {
        if (bidHistory) {
            if (collapsed) {
                return bidHistory.slice(0, DEFAULT_BIDS).map((bid) => {
                    return {...bid, isBidder: bidderAlias === bid.bidderAlias ? <strong>You</strong> : '', time: format(parseISO(bid.time), 'dd/MM/yyyy HH:mm:ss'), price: `£${new Intl.NumberFormat('en-GB').format(bid.price)}`};
                });
            }
            else {
                return bidHistory.map((bid) => {
                    return {...bid, isBidder: bidderAlias === bid.bidderAlias ? <strong>You</strong> : '', time: format(parseISO(bid.time), 'dd/MM/yyyy HH:mm:ss'), price: `£ ${new Intl.NumberFormat('en-GB').format(bid.price)}`};
                }); 
            }
        }

        return [];

    }, [collapsed, bidHistory]);

    const {  
        getTableProps, 
        getTableBodyProps, 
        headerGroups, 
        rows, 
        prepareRow
    } = useTable({ columns: tableColumns, data: tableData });

    return (
        <>
            <table 
            className="mt-3 w-full bid-history-table"
            {...getTableProps()}>
                <thead>
                {
                    headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} >
                        {
                            headerGroup.headers.map((column) => {
                                return (
                                    <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                                )
                            }

                            )
                        }
                        </tr>
                    ))
                }
                </thead>
                <tbody
                {...getTableBodyProps()}>
                {
                    rows.map((row) => {
                        prepareRow(row);

                        return (
                            <tr
                            className="border-b-2 border-mid-gray text-sm"
                            {...row.getRowProps()}>
                            {
                                row.cells.map((cell) => {
                                    return (
                                    <td
                                    className={`py-3 text-center ${cell.value?.props?.children === 'You' ? 'transform translate-x-2' : ''}`}
                                    {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                    )
                                
                                })
                            }
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

        </>
    )
}