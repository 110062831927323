import React from 'react';

export default function PropertyPageBiddingPositionSection({ bidPrice, bidderPrice }) {
    let bidderPosition;

    if (bidderPrice) {
        if (bidPrice === bidderPrice) {
            bidderPosition = 'You are the highest bidder';
        }
        else {
            bidderPosition = 'You are no longer the highest bidder';
        }
    }
    else {
        bidderPosition = 'You are yet to bid';
    }

    return (
        <section className="py-3 px-10">
            <header>
                <h2 className="font-bold">BIDDING POSITION</h2>
            </header>
            <p className={`px-4 py-2 font-semibold text-center border-2 ${bidderPosition === 'You are the highest bidder' ? 'text-green-600 border-green-600' : 'text-air-red border-air-red'}`}>{bidderPosition}</p>
        </section>
    )
}