import React, { useEffect, useContext } from 'react';

import ValidationTypes from '@util/ValidationTypes';
import SelectFields from '@util/SelectFields';

import AuthContext from '@contexts/AuthContext';
import BidderRegistrationContext from '@contexts/BidderRegistrationContext';

import useForm from '@hooks/useForm';

import InputButton from '@components/forms/InputButton';
import FormInput from '@components/forms/FormInput';
import Select from '@components/forms/Select';

export default function BidderDashboardCompleteProfile({ setActiveStep }) {
    const { userData: {
        firstName,
        lastName,
        email,
        mobilePhoneNumber,
        landlinePhoneNumber,
    }} = useContext(AuthContext);
    const { completeProfileData, setCompleteProfileData } = useContext(BidderRegistrationContext);

    const registerFormRules = {
        firstName: [ValidationTypes.requiredString],
        lastName: [ValidationTypes.requiredString],
        dobDay: [ValidationTypes.requiredString],
        dobMonth: [ValidationTypes.requiredString],
        dobYear: [ValidationTypes.requiredString],
        email: [ValidationTypes.requiredString, ValidationTypes.validEmail],
        houseNumber: [ValidationTypes.requiredString],
        street: [ValidationTypes.requiredString],
        town: [ValidationTypes.requiredString],
        region: [ValidationTypes.requiredString],
        postCode: [ValidationTypes.requiredString],
        mobilePhoneNumber: [ValidationTypes.requiredString],
    };

    const { formData, setFormData, handleChange, handleSubmit, errorMapping } = useForm(completeProfileData ?? {
        firstName: '',
        middleNames: '',
        lastName: '',
        dobDay: '2',
        dobMonth: '1',
        dobYear: '2020',
        email: '',
        houseNumber: '',
        street: '',
        town: '',
        region: '',
        postCode: '',
        country: 'United Kingdom',
        mobilePhoneNumber: '',
        landlinePhoneNumber: '',
        solicitorFirm: '',
        solicitorEmail: '',
        solicitorName: '',
        solicitorPhoneNumber: '',
    }, registerFormRules);

    const handleSubmitSuccess = () => {
        setCompleteProfileData({...formData});
        setActiveStep(1);
    }

    // TODO Investigate this effect? (Shoulda done it since the beginning! Dumbo!!!)
    useEffect(() => {
        if (!completeProfileData && firstName) { // This is meant for the initial render of the page.
            setFormData({
                firstName,  
                middleNames: '',
                lastName,
                dobDay: '',
                dobMonth: '',
                dobYear: '',
                email,
                houseNumber: '',
                street: '',
                town: '',
                region: '',
                postCode: '',
                country: 'United Kingdom',
                solicitorFirm: '',
                solicitorEmail: '',
                solicitorName: '',
                solicitorPhoneNumber: '',
                mobilePhoneNumber,
                landlinePhoneNumber
            })

        }
    }, [firstName, completeProfileData]);
    
    return (
        <div className="flex flex-col items-center max-w-container-dashboard mx-auto">
            <h1 className="dashboard-header mb-1 text-4xl text-center">Complete Profile</h1>
            <h2 className="font-secondary font-bold text-primary-agency text-center mb-5">Fill in the remaining profile fields while updating fields where necessary. </h2>
            <form
            onSubmit={(e) => handleSubmit(e, handleSubmitSuccess)}
            className="w-full">
                <div className="rounded-lg p-6 sm:p-10 bg-white shadow-lg">
                    <h3 className="font-primary font-semibold inline-block border-b-4 border-primary-agency text-2xl sm:text-3xl mb-8">Your Information</h3>
                    <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5">
                        <FormInput
                        type="text"
                        label="First Name"
                        inputName="firstName"
                        value={formData.firstName}
                        error={errorMapping.firstName}
                        handleChange={handleChange} />
                        <FormInput
                        type="text"
                        label="Middle Name (Optional)"
                        inputName="middleNames"
                        value={formData.middleNames}
                        error={errorMapping.middleNames}
                        handleChange={handleChange} />
                        <FormInput
                        type="text"
                        label="Last Name"
                        inputName="lastName"
                        value={formData.lastName}
                        error={errorMapping.lastName}
                        handleChange={handleChange} />
                    </div>
                    <h3 className="font-primary font-semibold text-lg mt-8 mb-3">Date of Birth</h3>
                    <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5">
                        <Select 
                        label="Month"
                        name="dobMonth"
                        options={SelectFields.dobMonth}
                        value={formData.dobMonth}
                        error={errorMapping.dobMonth}
                        onChange={handleChange}
                        nameValueEq
                        labelClass="text-base" />
                        <Select 
                        label="Day"
                        name="dobDay"
                        options={SelectFields.dobDay}
                        value={formData.dobDay}
                        error={errorMapping.dobDay}
                        onChange={handleChange}
                        nameValueEq 
                        labelClass="text-base" />
                        <Select 
                        label="Year"
                        name="dobYear"
                        options={SelectFields.dobYear}
                        value={formData.dobYear}
                        error={errorMapping.dobYear}
                        onChange={handleChange}
                        nameValueEq 
                        labelClass="text-base" />
                    </div>
                    <FormInput 
                    type="text"
                    label="Email"
                    inputName="email"
                    inputProps={{
                        readOnly: true
                    }}
                    value={formData.email}
                    rootClass="mt-8" />
                    <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5 mt-8">
                        <FormInput 
                        type="text"
                        label="House Number"
                        inputName="houseNumber"
                        value={formData.houseNumber}
                        error={errorMapping.houseNumber} 
                        handleChange={handleChange} />
                        <FormInput 
                        type="text"
                        label="Street"
                        inputName="street"
                        value={formData.street}
                        error={errorMapping.street} 
                        handleChange={handleChange} />
                    </div>
                    <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5 mt-8">
                        <FormInput 
                        type="text"
                        label="Town"
                        inputName="town"
                        value={formData.town}
                        error={errorMapping.town} 
                        handleChange={handleChange}
                        rootClass="w-1/2" />
                        <FormInput 
                        type="text"
                        label="Region"
                        inputName="region"
                        value={formData.region}
                        error={errorMapping.region} 
                        handleChange={handleChange}
                        rootClass="w-1/2" />
                    </div>
                    <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5 mt-8">
                        <FormInput 
                        type="text"
                        label="Postcode"
                        inputName="postCode"
                        value={formData.postCode}
                        error={errorMapping.postCode} 
                        handleChange={handleChange} />
                        <Select 
                        label="Country"
                        name="country"
                        options={SelectFields.countries}
                        value={formData.country}
                        onChange={handleChange}
                        nameValueEq 
                        labelClass="text-base" />
                    </div>
                    <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5 mt-8">
                        <FormInput 
                        type="tel"
                        label="Mobile Phone"
                        inputName="mobilePhoneNumber"
                        value={formData.mobilePhoneNumber}
                        error={errorMapping.mobilePhoneNumber} 
                        handleChange={handleChange} />
                        <FormInput 
                        type="tel"
                        label="Landline Phone (Optional)"
                        inputName="landlinePhoneNumber"
                        value={formData.landlinePhoneNumber}
                        error={errorMapping.landlinePhoneNumber} 
                        handleChange={handleChange} />
                    </div>
                    <h3 className="font-primary font-semibold inline-block border-b-4 border-primary-agency text-2xl sm:text-3xl my-8">Solicitor Information (Optional)</h3>
                    <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5">
                        <FormInput 
                        type="text"
                        label="Solicitor Name"
                        inputName="solicitorName"
                        value={formData.solicitorName}
                        error={errorMapping.solicitorName} 
                        handleChange={handleChange} />
                        <FormInput 
                        type="text"
                        label="Solicitor Firm"
                        inputName="solicitorFirm"
                        value={formData.solicitorFirm}
                        error={errorMapping.solicitorFirm} 
                        handleChange={handleChange} />
                    </div>
                    <div className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5 mt-8">
                        <FormInput 
                        type="text"
                        label="Solicitor Email"
                        inputName="solicitorEmail"
                        value={formData.solicitorEmail}
                        error={errorMapping.solicitorEmail} 
                        handleChange={handleChange} />
                        <FormInput 
                        type="text"
                        label="Solicitor Phone Number"
                        inputName="solicitorPhoneNumber"
                        value={formData.solicitorPhoneNumber}
                        error={errorMapping.solicitorPhoneNumber} 
                        handleChange={handleChange} />
                    </div>
                </div>
                    <InputButton 
                    type="submit"
                    submitText="Next (Upload ID)" 
                    rootClass="mx-auto mt-8" />
            </form>
        </div>
    )
}