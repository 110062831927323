import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import SelectFields from '@util/SelectFields';

import AppContext from '@contexts/AppContext';

import useForm from '@hooks/useForm';

import Select from '@components/forms/Select';
import FormInput from '@components/forms/FormInput';

export default function HomepageSearch() {
    const history = useHistory();
    const { agencyData: { heroImageAddress } } = useContext(AppContext);
    
    const formValidationRules = {
        minPrice: [{
            test: (value, formData) => formData.maxPrice === '-1' || parseInt(value) <= parseInt(formData.maxPrice),
            errorMsg: 'Must be less than max price'
        }]
    };

    const { formData, handleChange, errorMapping, handleSubmit } = useForm({
        city: "",
        minPrice: "-1",
        maxPrice: "-1",
        bedRooms: "-1"
    }, formValidationRules);

    const handleSubmitSuccess = () => {
        history.push({
            pathname: '/search',
            search: qs.stringify(formData)
        });
    }

    return (
        <div className="relative p-4 md:p-8 lg:pt-28 lg:py-56">
            <div className="absolute inset-0 -z-1">
                <img 
                className="object-cover w-full h-full"
                src={heroImageAddress} 
                alt=""
                aria-hidden="true" />
                <div className="absolute inset-0 bg-black bg-opacity-20"></div>
            </div>
            <div className="max-w-[1300px] mx-auto">
                <div className="min-w-mobile md:w-3/4 lg:w-1/2">
                    <header>
                        <h1 className="tracking-wide leading-10 text-3xl text-center md:text-left md:text-5xl font-bold text-white">Find your next property</h1>
                    </header>
                    <form 
                    noValidate 
                    autoComplete="off" 
                    className="py-4 mt-4"
                    onSubmit={(e) => handleSubmit(e, handleSubmitSuccess)}>
                        <FormInput 
                        type="text"
                        placeholder="Enter an area"
                        inputName="city"
                        value={formData.city}
                        error={errorMapping.city}
                        handleChange={handleChange} />
                        <section className="flex flex-col sm:flex-row justify-between items-center space-y-5 sm:space-x-3 sm:space-y-0">
                            <header className="pb-0 sm:pb-4 p-4 pl-2 text-center">
                                <h2 className="text-2xl font-bold text-white">Filters</h2>
                            </header>
                            <Select 
                            name="minPrice"
                            options={SelectFields.price} 
                            error={errorMapping.minPrice} 
                            prependOption="£"
                            initOption={{
                                name: "Min Price",
                                value: "-1"
                            }}
                            onChange={handleChange} />

                            <Select 
                            name="maxPrice"
                            options={SelectFields.price} 
                            error={errorMapping.maxPrice} 
                            prependOption="£"
                            initOption={{
                                name: "Max Price",
                                value: "-1"
                            }}
                            onChange={handleChange} />

                            <Select 
                            name="bedRooms"
                            options={[{
                                name: '0+ Beds',
                                value: '0'
                            }, {
                                name: '1+ Beds',
                                value: '1'
                            }, {
                                name: '2+ Beds',
                                value: '2'
                            }, {
                                name: '3+ Beds',
                                value: '3'
                            }, {
                                name: '4+ Beds',
                                value: '4'
                            }]}
                            error={errorMapping.bedRooms} 
                            onChange={handleChange} />
                        </section>
                        <div className="mt-4 flex justify-center sm:justify-start">
                            <button className="px-20 py-3 bg-secondary-agency text-white rounded-md">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}