import axios from '../util/axios';



const post = (endpointType) => {
    return ({ endpoint, data, handleSuccess, handleFail, handleComplete }) => {
        axios.post(`/api/${endpointType}/${endpoint}`, data)
        .then((res) => {
            if (res.data.status) {
                if (handleComplete) {
                    handleComplete();
                }
                if (handleSuccess) {
                    handleSuccess(res.data.data);
                }
            }
            else {
                return Promise.reject(res.data.message);
            }
        })
        .catch((err) => {
            if (handleComplete) {
                handleComplete();
            }
            if (handleFail) {
                handleFail(err);
            }
        });
    }

}

const get = (endpointType) => {
    return ({ endpoint, handleSuccess, handleFail, handleComplete }) => {
        console.log(endpoint);
        axios.get(`/api/${endpointType}/${endpoint}`)
        .then((res) => {
            if (res.data.status) {
                if (handleComplete) {
                    handleComplete();
                }
                if (handleSuccess) {
                    handleSuccess(res.data.data);
                }
            }
            else {
                return Promise.reject(res.data.message);
            }
        })
        .catch((err) => {
            if (handleComplete) {
                handleComplete();
            }
            if (handleFail) {
                handleFail(err);
            }
        });
    }

}

export const bidder = { post: post('bidder'), get: get('bidder') };
export const agency = { post: post('agency') };
export const agent = { post: post('agent') };
