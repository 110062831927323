import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import PropertyCardTopBar from './PropertyCardTopBar';
import PropertyCardWatchedButton from './PropertyCardWatchedButton';

export default function PropertyCard({ productId, assetImages, title, address, town, county, bedRooms, bathRooms, assetType, bidEndTime, postCode, productStatus, publishDate, bidPrice, bidStartPrice }) {
    const [currImageIdx, setCurrImageIdx] = useState(0);
    const date = useMemo(() => {
        let res;

        switch (productStatus) {
            case 'PreMarket':
                res = (
                    <p className="font-semibold text-gray-500 text-lg mt-3">
                        Available soon
                    </p>
                );
                break;
            case 'ScheduledPreMarket':
                res = (
                    <p className="flex space-x-2 font-semibold text-gray-500 text-lg mt-3">
                        <span>Start Date:</span>
                        <time>{format(publishDate, 'dd/MM/yyyy HH:mm')}</time>
                        {/* TODO Change above to start time when added! */}
                    </p>
                );
                break;
            default:
                res = (
                    <p className="flex space-x-2 font-semibold text-gray-500 text-lg mt-3">
                        <span>End Date:</span>
                        <time>{format(bidEndTime, 'dd/MM/yyyy HH:mm')}</time>
                    </p>
                )
                break;
        }

        return res;
    }, [productStatus, bidEndTime]);

    const price = useMemo(() => {
        let res;
        const hasBid = bidStartPrice !== bidPrice;

        switch (productStatus) {
            case 'PreMarket':
            case 'ScheduledPreMarket':
                res = (
                    <section>
                        <header className="font-semibold text-gray-500 text-lg">
                            <h3>Start Bid</h3>
                        </header>
                        <p className="text-2xl sm:text-3xl font-bold text-primary-agency">£{new Intl.NumberFormat('en-GB').format(bidStartPrice)}</p>
                    </section>
                );
                break;
            default:
                res = (
                    <section>
                        <header className="font-semibold text-gray-500 text-lg">
                            <h3>{hasBid ? 'Current' : 'Start'} Bid</h3>
                        </header>
                        <p className="text-2xl sm:text-3xl font-bold text-primary-agency">£{new Intl.NumberFormat('en-GB').format(hasBid ? bidPrice : bidStartPrice)}</p>
                    </section>
                );
                break;
        }

        return res;
    }, [productStatus, bidStartPrice, bidPrice]);

    return (
        <div className="relative border border-gray-300 rounded-lg hover:shadow-lg">
            {
                !!assetImages.length && (
                    <>
                        <button 
                        className="property-c-left-arrow"
                        onClick={(e) => {
                            if (currImageIdx - 1 !== -1) {
                                setCurrImageIdx(currImageIdx - 1);
                            }
                        }}
                        >
                            <KeyboardArrowLeftIcon />
                        </button>
                        <button 
                        className="property-c-right-arrow"
                        onClick={(e) => {
                            if (currImageIdx + 1 !== assetImages.length) {
                                setCurrImageIdx(currImageIdx + 1);
                            }
                        }}>
                            <KeyboardArrowRightIcon className="transform translate-x-px"/>
                        </button>
                    </>
                )
            }

            <Link to={`/property/${productId}`} className="font-primary bg-white  mb-5 lg:mb-0">
                <PropertyCardTopBar images={assetImages} status={productStatus} bidEndTime={bidEndTime} currImageIdx={currImageIdx} setCurrImageIdx={setCurrImageIdx} />
                <div className="p-8 pb-12">
                    <h2 className="font-semibold text-xl sm:text-2xl">{title}</h2>
                    <section className="flex items-center space-x-2 mt-3">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" height="18" fill="var(--color-primary-agency)">
                            <path d="M256,0C147.925,0,60,87.925,60,196c0,34.594,9.14,68.605,26.431,98.355c4.031,6.936,8.537,13.695,13.395,20.09
                                L247.484,512h17.031l147.659-197.554c4.857-6.396,9.363-13.154,13.395-20.09C442.86,264.605,452,230.594,452,196
                                C452,87.925,364.075,0,256,0z M256,261c-35.841,0-65-29.159-65-65s29.159-65,65-65s65,29.159,65,65S291.841,261,256,261z"/>
                        </svg>
                        <p className="font-semibold text-gray-500 text-lg">{address}, {town}, {county}, {postCode}</p>
                    </section>
                    <div className="font-semibold text-gray-500 text-lg">
                        <div className="flex flex-col sm:flex-row items-start space-y-3 sm:space-y-0 sm:space-x-5 mt-3">
                            <div className="flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="30" height="30">
                                        <path d="M469.333,225.088V128c0-29.397-23.936-53.333-53.333-53.333H96c-29.397,0-53.333,23.936-53.333,53.333v97.088
                                            C18.368,230.037,0,251.584,0,277.333v64V384v42.667c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.667-10.667v-32h469.333
                                            v32c0,5.888,4.779,10.667,10.667,10.667c5.888,0,10.667-4.779,10.667-10.667V384v-42.667v-64
                                            C512,251.584,493.632,230.037,469.333,225.088z M64,128c0-17.643,14.357-32,32-32h320c17.643,0,32,14.357,32,32v96h-29.355
                                            c2.091-5.867,2.624-12.203,1.067-18.432l-10.667-42.667c-3.563-14.272-16.341-24.235-31.04-24.235h-68.672
                                            c-17.643,0-32,14.357-32,32v42.667c0,3.755,0.768,7.317,1.963,10.667h-46.592c1.195-3.349,1.963-6.912,1.963-10.667v-42.667
                                            c0-17.643-14.357-32-32-32h-68.672c-14.699,0-27.477,9.984-31.04,24.235l-10.667,42.667c-1.557,6.229-1.024,12.565,1.067,18.432
                                            H64V128z M397.077,219.904c-2.027,2.603-5.099,4.096-8.405,4.096h-79.339c-5.888,0-10.667-4.779-10.667-10.667v-42.667
                                            c0-5.888,4.779-10.667,10.667-10.667h68.672c4.907,0,9.152,3.328,10.347,8.085l10.667,42.667
                                            C399.829,213.952,399.125,217.301,397.077,219.904z M213.312,170.645v42.667c0,5.888-4.779,10.667-10.667,10.667h-79.339
                                            c-3.307,0-6.379-1.493-8.405-4.096s-2.752-5.952-1.941-9.152l10.667-42.667c1.216-4.736,5.461-8.064,10.347-8.085h68.672
                                            C208.533,159.979,213.312,164.757,213.312,170.645z M490.667,373.333H21.333V352h469.333V373.333z M490.667,330.667H21.333
                                            v-53.333c0-17.643,14.357-32,32-32h69.995h79.339h106.667h79.339h69.995c17.643,0,32,14.357,32,32V330.667z"/>
                                </svg>
                                <span>{assetType === 'Land' || assetType === 'Other' ? 'N/A' : (bedRooms > 1 ? bedRooms : 1)}</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m19.5 24c-.276 0-.5-.224-.5-.5v-17c0-3.033-2.468-5.5-5.5-5.5h-.5c-2.481 0-4.5 2.019-4.5 4.5 0 .276-.224.5-.5.5s-.5-.224-.5-.5c0-3.033 2.468-5.5 5.5-5.5h.5c3.584 0 6.5 2.916 6.5 6.5v17c0 .276-.224.5-.5.5z"/>
                                    <path d="m11.5 10h-7c-.276 0-.5-.224-.5-.5v-.5c0-2.206 1.794-4 4-4s4 1.794 4 4v.5c0 .276-.224.5-.5.5zm-6.5-1h6c0-1.654-1.346-3-3-3s-3 1.346-3 3z"/>
                                    <circle cx="10" cy="13" r="1"/>
                                    <circle cx="8" cy="17" r="1"/>
                                    <circle cx="4" cy="17" r="1"/>
                                    <circle cx="12" cy="17" r="1"/>
                                    <circle cx="6" cy="13" r="1"/>
                                </svg>
                                <span>{assetType === 'Land' || assetType === 'Other' ? 'N/A' : (bathRooms > 1 ? bathRooms : 1)}</span>
                            </div>
                            <div className="flex sm:items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 476.912 476.912">
                                    <path d="M461.776,209.408L249.568,4.52c-6.182-6.026-16.042-6.026-22.224,0L15.144,209.4c-3.124,3.015-4.888,7.17-4.888,11.512
                                        c0,8.837,7.164,16,16,16h28.2v224c0,8.837,7.163,16,16,16h112c8.837,0,16-7.163,16-16v-128h80v128c0,8.837,7.163,16,16,16h112
                                        c8.837,0,16-7.163,16-16v-224h28.2c4.338,0,8.489-1.761,11.504-4.88C468.301,225.678,468.129,215.549,461.776,209.408z
                                            M422.456,220.912c-8.837,0-16,7.163-16,16v224h-112v-128c0-8.837-7.163-16-16-16h-80c-8.837,0-16,7.163-16,16v128h-112v-224
                                        c0-8.837-7.163-16-16-16h-28.2l212.2-204.88l212.28,204.88H422.456z"/>
                                </svg>
                                <span>{assetType}</span>
                            </div>
                        </div>
                    </div>
                    {date}
                    <div className="mt-8 flex justify-between items-center">
                        {price}
                        <PropertyCardWatchedButton propertyId={productId} />
                    </div>
                </div>
            </Link>
        </div>
        
    )
};
