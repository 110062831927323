import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';

import { agency } from '@api/api';

import axios from '@util/axios';
import socketController from '@util/socket';
import { transformStatus } from '@util/product';
import { BID_END_BUFFER } from '@util/site-constants';

import useBidSocket from '@hooks/useBidSocket';

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

import PropertyPageDescription from './components/PropertyPageDescription';
import PropertyPageImageSlider from './components/PropertyPageImageSlider';
import PropertyPageStatusSection from './components/PropertyPageStatusSection';
import PropertyPageBidColumn from './components/PropertyPageBidColumn';
import PropertyPageWatchButton from './components/PropertyPageWatchButton';
import Footer from '@components/Footer';
import DocumentView from '@components/DocumentView';
import Tabs from '@components/Tabs';
import PropertyPageMap from './components/PropertyPageMap';


/* 
    Renders the entire property page.
*/
export default function PropertyPage() {
    const { productId } = useParams();

    const { addNotification, stripePromise, setServerError, agencyData: { agencyId, branches } } = useContext(AppContext);
    const { userType, userData, setUserData, isUserChecked } = useContext(AuthContext);
    const {
        isVerified, 
        isFullyRegistered, 
        isCardComplete, 
        bidsMaxPrice, 
        bidderStatus,
        bidderAlias
    } = userData ?? {};
    const { handleNewPrice, generateUuid, outbid, setOutbid } = useBidSocket();
    const bidsMaxPriceRef = useRef({});

    const [productData, setProductData] = useState(null);
    const [productError, setProductError] = useState(null);
    const [bidLoading, setBidLoading] = useState(false);
    const [_postBidError, setPostBidError] = useState('');
    const [agencyData, setAgencyData] = useState(null);
    const bidEndTimeoutRef = useRef(null);
    const reserveStatusRef = useRef(null);

    const branchInfo = useMemo(() => {
        return productData ? branches.find((branch) => branch.branchId === productData.branchId) : null;
    }, [productData, branches]);
    /* 
        Effect
        Keep bidsMaxPrice up to date to be used in a callback.
    */
    useEffect(() => {
        bidsMaxPriceRef.current = bidsMaxPrice;
    }, [bidsMaxPrice]);

    /* 
        Effect
    */
    useEffect(() => { // Initial setting of outbid! The rest will be determined by productData changing live in useBidSocket
        if (bidsMaxPrice && productData && bidsMaxPrice[productId]) {
            if (bidsMaxPrice[productId] < productData.bidPrice) {
                setOutbid(true);
            }
        }
    }, [bidsMaxPrice, productData]);

    /**
     * Handles logic for submitting bid. Sets state based on results.
     * @param {number} bidPrice Represents the price the user wants to bid at
     */
    const handleBid = ({ bidPrice }) => {
        let uuid = generateUuid();
        setBidLoading(true);

        agency.post({
            endpoint: 'product/bid',
            data: {
                productId: productData.productId,
                bidPrice,
                bidUuid: uuid
            },
            handleSuccess: (data) => { 
                setOutbid(false); 
                setUserData((userData) => {
                    return {
                        ...userData, 
                        bidsMaxPrice: { ...userData.bidsMaxPrice, [productData.productId]: parseInt(bidPrice) },
                        myBids: [...userData.myBids, {
                            productId: productData.productId,
                            price: parseInt(bidPrice)
                        }]
                    };
                })
            },
            handleFail: (err) => setPostBidError(err),
            handleComplete: () => {
                setBidLoading(false);
            }
        });
    }

    /**
     * Handles situation where bid ends (timer reaches 0). 
     * Creates a buffer of time (i.e. grace period) where any late
     * bids can still come in and add time to the timer.
     * Otherwise, if no bids, bid will end, and appropriate action will be taken
     * (i.e. socket should be disconnected, product status should be updated based on current info.)
     */
    const handleBidEnd = () => {
        if (!bidEndTimeoutRef.current) {
            bidEndTimeoutRef.current = setTimeout(() => {
                // // Disconnect socket (no longer listening!)
                // socketController.disconnect();
                // Update product status based on the latest reserveStatus.
                addNotification('The bid has ended.');
                setProductData((oldData) => ({ ...oldData, productStatus: oldData.reserveStatus !== 'No Reserve' ? (oldData.reserveStatus === 'Reserve Not Met' ? 'Ended' : 'Sold') : (oldData.bids > 0 ? 'Sold' : 'Ended')})); 
            }, BID_END_BUFFER);
        }
    };

    /*
        Effect
        If buffer timer for bid end exists and new product data comes in (from a late bid past timer 0),
        clear timer. Don't know if necessary since already doing it in new price handler.
    */
    // useEffect(() => {
    //     if (bidEndTimeoutRef.current) {
    //         clearTimeout(bidEndTimeoutRef.current);
    //         bidEndTimeoutRef.current = null;
    //     }
    // }, [productData]);
    
    /* 
        Effect
        Handles the initial population of product data.
        If product status is live, initialize websocket to
        listen for new price events.
    */
    useEffect(() => {
        if (isUserChecked) {
            let apiEndpoint = null;

            switch (userType) {
                case 'bidder':
                    apiEndpoint = 'data';
                    break;
                case 'agent':
                    apiEndpoint = 'dataAgent';
                    break;
                default:
                    apiEndpoint = 'data-min';
                    break;
            }

            axios.post(`/api/agency/product/${apiEndpoint}`, {
                agencyId,
                productId,
            })
            .then((res) => {
                console.log(res.data.data);
                if (res.data.status) {
                    if (res.data.data.productStatus === 'Live') { // Only connect socket when live (that's the only time we would check for new prices)
                        socketController.initializeSocket();
                        socketController.addEventListener('new-price', (data) => {
                            if (bidEndTimeoutRef.current) { // Make sure to clear grace period as we have a new bid, meaning 5 minutes of bid left.
                                clearTimeout(bidEndTimeoutRef.current);
                                bidEndTimeoutRef.current = null;
                            }
    
                            handleNewPrice(data, bidsMaxPriceRef.current, () => setProductData((productData) => { 
                                let bidEnded = (new Date(data.bidEndTime).getTime() - new Date(data.currentTime).getTime()) <= 0;
    
                                if (bidEnded) {
                                    socketController.disconnect();
                                }
                                // If end of bid, this is the new product status determined by reserveStauts.
                                const newProductStatus = data.reserveStatus !== 'No Reserve' ? (data.reserveStatus === 'Reserve Not Met' ? 'Ended' : 'Sold') : (productData.bids > 0 ? 'Sold' : 'Ended');

                                let newBidEndTime = parseISO(data.bidEndTime).getTime();

                                return { 
                                    ...productData, 
                                    bidPrice: data.price, 
                                    formattedBidPrice: new Intl.NumberFormat('en-GB').format(data.price),
                                    bids: productData.bids + 1,
                                    ...(userType  && { bidHistory: [{ time: data.currentTime, price: data.price, bidderAlias: data.signature }, ...productData.bidHistory] }),
                                    bidEndTime: newBidEndTime,
                                    reserveStatus: data.reserveStatus,
                                    extendedCount: data.extendedCount,
                                    ...(bidEnded && { productStatus: newProductStatus }), // If bid ended, update product status.,
                                    ...(data.extendedCount && { serverMS: newBidEndTime - (1000 * 60 * 5) }) // If time extended, make sure to extend 5 minuntes.
                                } }));
                        });
                        socketController.emitEvent('observer', {
                            productId: productId
                        });
                    }
                    setProductData({
                        ...res.data.data,
                        formattedBidStartPrice: new Intl.NumberFormat('en-GB').format(res.data.data.bidStartPrice),
                        formattedBidPrice: new Intl.NumberFormat('en-GB').format(res.data.data.bidPrice),
                        formattedGuidePrice: new Intl.NumberFormat('en-GB').format(res.data.data.guidePrice),
                        productStatus: transformStatus(res.data.data.productStatus),
                        bidEndTime: parseISO(res.data.data.bidEndTime).getTime(),
                        publishDate: res.data.data.publishDate ? format(parseISO(res.data.data.publishDate), 'EEEE, do MMMM yyyy HH:mm') : 'N/A (Not scheduled)'
                    });

                    if (res.data.data.productStatus === 'Live') { // Only listen if live. Don't care otherwise.
                        socketController.addEventListener('server-time', (data) => {
                            console.log(data);
                            setProductData((productData) => ({
                                ...productData,
                                serverMS: data.server_ms
                            }));
                        }); 
                    }
                }
                else {
                    setProductError(res.data.message);
                }
            })
            .catch((err) => {
                addNotification("There was an error getting the property information", <span>{err}</span>);
            });
        }
        

        return () => { // Remember to disconnect from socket!
            if (socketController.isConnected()) {
                socketController.disconnect();
            }
        }
    }, [isUserChecked]);



    /* 
        Effect:
        Whenever product data changes (i.e. initial data population, new bid), update the appropriate values.
    */
    useEffect(() => {
        if (productData) {
            reserveStatusRef.current = productData.reserveStatus;
        }
    }, [productData]);

    /*
        On page load, get agency data to display in contact tab.
    */
    useEffect(() => {
        console.log('mounted');
        (async () => {
            try {
                const res = await axios.post('/api/agency/getAgencyDetailsByProdId', {
                    productId,
                    agencyId
                });
                console.log(res.data.data);
                setAgencyData(res.data.data);
            }
            catch (e) {
                setServerError('Error obtaining agency details.');
            }
        })();
    }, []);

    const {
        title,
        address,
        town,
        county,
        geoLocation,
        postCode,
        assetClass,
        assetType,
        productStatus,
        publishDate,
        bidEndTime,
        bidStartPrice,
        bidPrice,
        bidHistory,
        assetImages,
        details,
        assetDocuments,
        reserveStatus,
        extendedCount,
        guidePrice,
        formattedBidPrice,
        formattedBidStartPrice,
        formattedGuidePrice,
        serverMS
    } = productData ?? {};

    return (
        <>
        <main>
        {
            productData ? (
                <>
                    <header className="bg-primary-agency">
                        <div className="flex flex-col lg:flex-row max-w-container-lg mx-auto pb-6 lg:pb-0 px-5 md:px-12 text-white">
                            <section className="lg:w-6/10 property-page-1300:w-7/10 flex-none mb-5 lg:mb-0 py-6 lg:pr-6">
                                <p className="flex items-center font-semibold">
                                    <span className={`inline-block w-4 h-4 mr-3 rounded-full ${productStatus === 'Live' ? 'bg-green-400' : 'bg-gray-500'}`}></span>
                                    {productStatus === 'Live' ? 'Live, real-time bidding' : productStatus}
                                </p>
                                <h1 className="font-bold text-2xl sm:text-3xl">{title}</h1>
                                <p className="font-bold text-2xl sm:text-3xl leading-none mb-5">{address}, {town}, {county}, {postCode}</p>
                                <div className="flex flex-col sm:flex-row">
                                    <div>
                                        <p className="font-semibold">{assetClass}, {assetType}</p>
                                        <p className="font-semibold">Conditional Online Auction {guidePrice !== -1 ? `| Guide Price: £${formattedGuidePrice}` : ''}</p>
                                    </div>
                                    {
                                        userType === 'bidder' ? (
                                            <PropertyPageWatchButton productId={productId} />
                                        ) : (
                                            <Link to="/bidder/login" className="self-start flex items-center sm:ml-auto mt-3 sm:mt-0 space-x-3">
                                                <div className="text-white">
                                                    <svg width="24" height="24" fill="currentColor" viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0"/>
                                                    </svg>
                                                </div>
                                                <p className="font-semibold">Add to Saved Properties</p>
                                            </Link>
                                        )
                                    }

                                </div>
                            </section>
                            <PropertyPageStatusSection 
                            productStatus={productStatus}
                            publishDate={publishDate}
                            bidEndTime={bidEndTime}
                            isExtended={extendedCount}
                            serverMS={serverMS}
                            handleBidEnd={handleBidEnd}
                            isSuccessfulBidder={bidsMaxPrice ? productStatus !== 'Live' && bidsMaxPrice[productId] === bidPrice : false} />
                        </div>
                    </header>
                    <div className="flex flex-col lg:flex-row max-w-container-lg mx-auto mt-4 lg:mt-0 px-5 md:px-12 justify-center">
                        <section className="flex flex-col lg:w-6/10 property-page-1300:w-7/10 mb-5 flex-none">
                            <PropertyPageImageSlider images={assetImages} />
                            <Tabs tabList={["Description", "Documents", "Map", "Contact"]} rootClass="shadow-lg mt-5 mx-3 mb-5" tabContentClass="h-tabs overflow-y-auto">
                                <PropertyPageDescription details={details} />
                                <section>
                                {
                                    isVerified ? (
                                        <DocumentView documents={assetDocuments} />
                                    ) : (
                                        <p className="font-secondary font-bold text-primary text-lg text-center">As a bidder, you must be logged in and have your email verified to view this property’s documents.</p>
                                    )
                                }
                                </section>

                                <PropertyPageMap geoLocation={geoLocation} />
                                <section>
                                    <header>
                                        <h2 className="font-bold text-3xl text-center sm:text-left">{agencyData?.name}</h2>
                                    </header>
                                    <div className="flex flex-col-reverse sm:flex-row mt-8">
                                        {branchInfo && (
                                            <div className="flex-1 flex flex-col mt-12 sm:mt-0 space-y-5">
                                                <div>
                                                    <span className="inline-block text-lg font-semibold">Branch Address</span>
                                                    <span className="block text-lg">{branchInfo.address}</span>
                                                </div>
                                                <div>
                                                    <span className="inline-block text-lg font-semibold">Branch Name</span>
                                                    <span className="block text-lg">{branchInfo.name}</span>
                                                </div>
                                                <div>
                                                    <span className="inline-block text-lg font-semibold">Branch Contact Phone Number</span>
                                                    <span className="block text-lg">{branchInfo.phone}</span>
                                                </div>
                                                <div>
                                                    <span className="inline-block text-lg font-semibold">Branch Email</span>
                                                    <span className="block text-lg">{branchInfo.email}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="flex-1 flex justify-center sm:ml-8">
                                            {
                                                agencyData && (
                                                    <img className="object-contain" src={agencyData.logoAddress} alt={`${agencyData.name} logo`} />
                                                )
                                            }
                                        </div>
                                    </div>
                                </section>
                            </Tabs>
                        </section>
                        <PropertyPageBidColumn 
                        userType={userType}
                        assetDocuments={assetDocuments}
                        stripePromise={stripePromise}
                        bidHistory={bidHistory}
                        publishDate={publishDate}
                        bidPrice={bidPrice}
                        bidStartPrice={bidStartPrice}
                        bidsMaxPrice={bidsMaxPrice}
                        formattedBidPrice={formattedBidPrice}
                        formattedBidStartPrice={formattedBidStartPrice}
                        isFullyRegistered={isFullyRegistered}
                        isVerified={isVerified}
                        isCardComplete={isCardComplete}
                        bidderStatus={bidderStatus}
                        productId={productId}
                        bidEndTime={bidEndTime} 
                        bidderAlias={bidderAlias}
                        handleBid={handleBid}
                        handleBidEnd={handleBidEnd} 
                        productStatus={productStatus}
                        isOutbid={outbid}
                        reserveStatus={reserveStatus}
                        bidLoading={bidLoading} />
                    </div>
                    <Footer />
                </>
            )
            :
            (
                productError ? (
                    <>
                        <div className="p-40 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                            width="100" 
                            height="100" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            stroke="var(--color-primary-agency)" 
                            strokeWidth="2" 
                            strokeLinecap="round" 
                            strokeLinejoin="round"
                            className="inline"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                            <h1 className="font-bold text-3xl mt-4">{productError}</h1>
                        </div>
                        <Footer />
                    </>
                ) : (
                    <div className="fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center">
                        <CircularProgress size="5rem" color="secondary" />
                        <h1 className="font-primary font-bold text-3xl text-center mt-3">Loading property info...</h1>
                    </div>
                )

            )
            
        }

        </main>

        </>
    )
}