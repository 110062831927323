import React, { useState, useContext } from 'react';

import AppContext from '@contexts/AppContext';
import AuthContext from '@contexts/AuthContext';

import useForm from '@hooks/useForm';
import useAPI from '@hooks/useAPI';

import InputButton from '@components/forms/InputButton';

export default function BidderDashboardHomeSolicitorForm({ solicitorInfo, setIsEditing }) {
    const { addNotification } = useContext(AppContext);
    const { setUserData } = useContext(AuthContext);

    const [editedDetails, setEditedDetails] = useState(false);
    const { solicitorName, solicitorEmail, solicitorFirm, solicitorPhoneNumber } = solicitorInfo;

    // const rules = {
    //     solicitorName: [ValidationTypes.requiredString],
    //     solicitorEmail: [ValidationTypes.requiredString],
    //     solicitorFirm: [ValidationTypes.requiredString],
    //     solicitorPhoneNumber: [ValidationTypes.requiredString]
    // };

    const { formData, errorMapping, handleChange, handleSubmit } = useForm({
        solicitorName,
        solicitorEmail,
        solicitorFirm,
        solicitorPhoneNumber
    });

    const { loading, err, makeRequest } = useAPI({
        method: 'post',
        endpoint: '/api/bidder/solicitorDetailsUpdate',
        handleSuccess: () => {
            addNotification('Solicitor details updated!');
            setUserData((userData) => ({ ...userData, ...formData }));
        }
    });

    /**
     * Checks to see if currently active input has been edited. If so, enable the submit button!
     */
    const customHandleChange = (e) => {
        handleChange(null, () => {
            let newFormData = { ...formData, [e.target.name]: (e.target.type === "checkbox" ? e.target.checked : e.target.value) };
            
            if (solicitorInfo[e.target.name] !== e.target.value) {
                setEditedDetails(true);
            }
            else {
                setEditedDetails(false);
            }

            return newFormData;
        });
    }

    const handleSubmitSuccess = async () => {
        if (editedDetails) { // ONLY make request if data changed.
            await makeRequest(formData);

            setIsEditing(false);
        }
    };

    return (
        <form onSubmit={(e) => handleSubmit(e, handleSubmitSuccess)}>
            <dl className="mt-5 space-y-8">
                <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-5">
                    <dt className="sm:w-1/3 sm:flex-none text-primary-agency font-semibold text-lg">Solicitor Name</dt>
                    <div className="relative flex-grow self-stretch sm:self-start">
                        <input className="form-input" type="text" name="solicitorName" value={formData.solicitorName} onChange={customHandleChange} />
                        <p className="absolute mt-1 form-error-text">{errorMapping.solicitorName}</p>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-5">
                    <dt className="sm:w-1/3 sm:flex-none text-primary-agency font-semibold text-lg">Solicitor Email</dt>
                    <div className="relative flex-grow self-stretch sm:self-start">
                        <input className="form-input" type="text" name="solicitorEmail" value={formData.solicitorEmail} onChange={customHandleChange} />
                        <p className="absolute mt-1 form-error-text">{errorMapping.solicitorEmail}</p>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-5">
                    <dt className="sm:w-1/3 sm:flex-none text-primary-agency font-semibold text-lg">Solicitor Firm</dt>
                    <div className="relative flex-grow self-stretch sm:self-start">
                        <input className="form-input" type="text" name="solicitorFirm" value={formData.solicitorFirm} onChange={customHandleChange} />
                        <p className="absolute mt-1 form-error-text">{errorMapping.solicitorFirm}</p>
                    </div>                    
                </div>
                <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-5">
                    <dt className="sm:w-1/3 sm:flex-none text-primary-agency font-semibold text-lg">Solicitor Phone Number</dt>
                    <div className="relative flex-grow self-stretch sm:self-start">
                        <input className="form-input" type="text" name="solicitorPhoneNumber" value={formData.solicitorPhoneNumber} onChange={customHandleChange} />
                        <p className="absolute mt-1 form-error-text">{errorMapping.solicitorPhoneNumber}</p>
                    </div>                    
                </div>
            </dl>
            {
                err && (
                    <p className="text-center my-2 text-air-red">{err.type === 'client' ? 'Error:' : 'Server error: Status Code'} {err.context}</p>
                )
            }
            <InputButton 
            submitText="Update Solicitor Details"
            rootClass="mx-auto mt-10 bg-primary-agency"
            loading={loading}
            disabled={!editedDetails} />
        </form>
    )
}