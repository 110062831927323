export const transformStatus = (status) => {
    if (status === 'PreMarket') {
        return 'Pre-Market';
    }
    else if (status === 'ScheduledPreMarket') {
        return 'Scheduled Pre-Market';
    }
    else if (status.includes('-')) {
        return status.split('-')[0];
    }
    else {
        return status;
    }
};