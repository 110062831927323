import React, { useState, useContext, useMemo } from 'react';

import axios from '@util/axios';
import ValidationTypes from '@util/ValidationTypes';

import AppContext from '@contexts/AppContext';

import useForm from '@hooks/useForm';

import FormInput from '@components/forms/FormInput';
import Select from '@components/forms/Select';
import Footer from '@components/Footer';


export default function ContactPage() {
    const { agencyData: { branches } } = useContext(AppContext);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [branchName, setBranchName] = useState(branches[0].name);
    const branchInfo = useMemo(() => {
        if (branchName) {
            return branches.find((branch) => branch.name === branchName);
        }
        else {
            return null;
        }
    }, [branchName]);

    const formRules = {
        name: [ValidationTypes.requiredString],
        phoneNumber: [ValidationTypes.requiredString],
        email: [ValidationTypes.requiredString],
        message: [ValidationTypes.requiredString]
    };

    const { formData, handleChange, handleSubmit, errorMapping } = useForm({
        name: '',
        phoneNumber: '',
        email: '',
        message: ''
    }, formRules);

    /**
     *  Sends email using server API.
     */
    const handleSendEmail = async () => {
        // Send email
        try {
            const res = await axios.post('/api/agency/contactUs', {
                ...formData
            });
    
            if (!res.data.status) {
                throw new Error('Email send failed.');
            }

            setSuccess(true);
        }
        catch (e) {
            setError('Email send failed.');
        }

    }

    return (
        <div>
            <header className="bg-primary-agency py-20 px-4 space-y-5 text-white">
                <div className="max-w-[1100px] mx-auto space-y-6">
                    <h1 className="font-bold text-3xl sm:text-4xl ">How can we help you?</h1>
                    <p className="font-semibold text-lg">
                        Please use one of the methods below to reach us and we'll get back in
                        touch as soon as possible.
                    </p>
                </div>
            </header>
            <main className="py-16 px-4">
                <div className="max-w-[1100px] mx-auto flex flex-col md:flex-row justify-center md:space-x-16">
                    <form className="flex-1 space-y-8 p-4 md:p-8 pb-12 border border-gray-300 rounded-md" onSubmit={(e) => handleSubmit(e, handleSendEmail)}>
                        <FormInput 
                        type="text"
                        placeholder="Name"
                        inputName="name"
                        value={formData.name}
                        error={errorMapping.name}
                        handleChange={handleChange} />
                        <FormInput 
                        type="text"
                        placeholder="Telephone"
                        inputName="phoneNumber"
                        value={formData.phoneNumber}
                        error={errorMapping.phoneNumber}
                        handleChange={handleChange} />
                        <FormInput 
                        type="text"
                        placeholder="Email"
                        inputName="email"
                        value={formData.email}
                        error={errorMapping.email}
                        handleChange={handleChange} />
                        <div className="relative">
                            <textarea 
                            className={`form-input ${errorMapping.message ? 'border-2 border-red-500 hover:border-red-500 focus:border-red-500' : ''}`}
                            name="message"
                            placeholder="Message"
                            value={formData.message}
                            onChange={handleChange}>
                            </textarea>
                            {
                                errorMapping.message && (
                                    <p className="absolute mt-1 text-red-500 text-sm font-semibold">{errorMapping.message}</p>
                                )
                            }
                        </div>

                        <button className="w-full py-3 text-center bg-secondary-agency text-white rounded-full">Send</button>
                        {
                            error && (
                                <p className="text-center text-red-500">{error}</p>
                            )
                        }
                        {
                            success && (
                                <p className="text-center text-xl font-bold">Email Sent!</p>
                            )
                        }
                        <p className="text-sm text-center font-semibold">By filling in this form you agree to our privacy policy.</p>
                    </form>
                    <section className="flex-1 mt-8 md:mt-0 space-y-6">
                        <header>
                            <h2 className="text-2xl font-bold">Branch Contact Information</h2>
                            <Select
                            label="Branch Name" 
                            value={branchName}
                            options={branches.map((branch) => branch.name)}
                            onChange={(e) => setBranchName(e.target.value)}
                            nameValueEq 
                            rootClass="mt-5" />
                        </header>
                        <div className="space-y-2">
                            <h2 className="font-bold text-lg">Branch Address</h2>
                            <p className="font-semibold text-primary-agency">{branchInfo.address}</p>
                        </div>
                        <div className="space-y-2">
                            <h2 className="font-bold text-lg">Branch Phone Number</h2>
                            <p className="font-semibold text-primary-agency">{branchInfo.phone}</p>
                        </div>
                        <div className="space-y-2">
                            <h2 className="font-bold text-lg">Branch Email</h2>
                            <p className="font-semibold text-primary-agency">{branchInfo.email}</p>
                        </div>
                        
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    )
}