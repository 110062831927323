// Can be extended if more common rules are used!
const ValidationTypes = {
    requiredString: {
        test: (value) => value?.length,
        errorMsg: "This is a required field"
    },
    validEmail: {
        // Uses the email regex for a regular browser's input type="email" email check. May not be fully comprehensive, but does the job. 
        test: (value) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value),
        errorMsg: "Invalid email address"
    }
}

export default ValidationTypes;