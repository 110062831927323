import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

export default function FormStepper({ steps, activeStepError, errors, rootClass, nonLinear, ...rest }) {

    return (
        <Stepper 
        className={rootClass}
        {...rest}>
        {
            steps.map((step, idx) => 
                <Step key={step}>
                    <StepLabel 
                    error={nonLinear && errors[idx]}>{step}</StepLabel>
                </Step>
            )
        }
        </Stepper>
    )
};

