import React, { useState, useMemo } from 'react';

import BaseTimer from '@components/timers/BaseTimer';
import InputButton from '@components/forms/InputButton';


export default function WarnInactiveNotification({ handleLogOut, handleKeepLoggedIn }) {
    const [loadingAPI, setLoadingAPI] = useState(null);
    const memoizedTime = useMemo(() => Date.now(), []);
    const memoizedEndTime = useMemo(() => Date.now() + 59 * 1000, []);
    const logOut = async () => {
        setLoadingAPI('logOut');

        await handleLogOut();

        setLoadingAPI(null);
    };

    const keepLoggedIn = async () => {
        setLoadingAPI('keepLoggedIn');

        await handleKeepLoggedIn();

        setLoadingAPI(null);
    };
    
    return (
        <div>
            <p>
                You will be logged out for inactivity in&nbsp; 
                <BaseTimer startTime={memoizedTime} endTime={memoizedEndTime}>
                {
                    (timeLeft) => (
                        <span className="font-semibold">{timeLeft.seconds} seconds</span>
                    )
                }
                </BaseTimer>
            </p>
            <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3 mt-3">
                <InputButton submitText="Log out" inputStyle="inverse" loading={loadingAPI === 'logOut'} onClick={logOut} />
                <InputButton submitText="Keep me logged in" inputStyle="inverse" loading={loadingAPI === 'keepLoggedIn'} onClick={keepLoggedIn} />
            </div>
        </div>
    )
}