import React, { useContext, useState, useRef } from 'react';

import { bidder } from '@api/api';

import AppContext from '@contexts/AppContext';

import useLoading from '@hooks/useLoading';

import InputButton from '@components/forms/InputButton';

import BidderDashboardEditProfile from './BidderDashboardEditProfile';

export default function BidderDashboardProfile() {
    const { bidderData: { firstName, middleNames, lastName, email, houseNumber, street, town, region, postCode, country, mobilePhoneNumber, landlinePhoneNumber, 
        solicitorFirm, solicitorEmail, dobDay, dobMonth, dobYear, newsletter, bidderToken, bidderSecret }, setBidderData, addNotification } = useContext(AppContext);

    const [editMode, setEditMode] = useState(false);
    const [editedProfile, setEditedProfile] = useState(false);

    const { loading, setLoading } = useLoading();
    const formRef = useRef(null);

    const handleEditSuccess = (formData) => {
        setLoading(true);
        bidder.post({
            endpoint: 'profileDetailsUpdate',
            data: {
                bidderToken,
                bidderSecret,
                ...formData
            },
            handleComplete: () => { setEditMode(false); setLoading(false); },
            handleSuccess: (_) => { setBidderData((bidderData) => { return {...bidderData, ...formData } }) },
            handleFail: (err) => addNotification(err)
        });
    }

    return (
        <div>
            <div className="flex flex-col items-center sm:block">
                <h1 className="page-header text-4xl sm:text-5xl">Your Profile</h1>
                {
                    !editMode
                    ?
                    (
                        <InputButton 
                        submitText="Edit Profile"
                        imageTitle="pencil"
                        imagePosition="left"
                        type="button" 
                        rootClass="mb-5 bidder-profile-md:mb-0 bidder-profile-md:ml-10 flex bidder-profile-md:inline-flex text-xl"
                        rounded="lg" 
                        disabled={!firstName}
                        onClick={(e) => firstName && setEditMode(!editMode)} />
                    )
                    :
                    (
                        <div className="lg:inline-block mb-3 md:mb-5 lg:mb-0">
                            <InputButton 
                            submitText="Cancel" 
                            imageTitle="clear"
                            imagePosition="left"
                            type="button"
                            inline
                            rootClass="lg:ml-10 text-xl" 
                            rounded="lg" 
                            onClick={(e) => setEditMode(false)}/>
                            <InputButton 
                            submitText="Finish Editing"
                            imageTitle={loading ? 'loading' : 'check'}
                            imagePosition="left"
                            type="button"
                            inline
                            disabled={!editedProfile}
                            rootClass="ml-5 text-xl"
                            rounded="lg" 
                            onClick={(e) => formRef.current && formRef.current.requestSubmit() }/>
                        </div>
                    )
                }

            </div>
            {
                !editMode 
                ?
                (
                    <div className="flex flex-col space-y-5 mt-8 sm:mt-0">
                        <div>
                            <h3 className="profile-label">Full Name</h3>
                            <span className="profile-value">{firstName} {lastName}</span>
                        </div>
                        <div>
                            <h3 className="profile-label">Email</h3>
                            <span className="profile-value">{email}</span>
                        </div>
                        <div>
                            <h3 className="profile-label">Address</h3>
                            <span className="profile-value">{`${houseNumber} ${street}`}</span>
                        </div>
                        <div>
                            <h3 className="profile-label">Town</h3>
                            <span className="profile-value">{town}</span>
                        </div>
                        <div>
                            <h3 className="profile-label">Region</h3>
                            <span className="profile-value">{region}</span>                                
                        </div>
                        <div>
                            <h3 className="profile-label">Postcode</h3>
                            <span className="profile-value">{postCode}</span>
                        </div>
                        <div>
                            <h3 className="profile-label">Country</h3>
                            <span className="profile-value">{country}</span>                                
                        </div>
                        <div>
                            <h3 className="profile-label">Mobile Phone</h3>
                            <span className="profile-value">{mobilePhoneNumber}</span>
                        </div>
                        <div>
                            <h3 className="profile-label">Landline Phone</h3>
                            <span className="profile-value">{landlinePhoneNumber === undefined ? '' : (landlinePhoneNumber || 'N/A')}</span>
                        </div>
                    </div>
                )
                :
                (
                    <BidderDashboardEditProfile 
                    initFormData={{
                        firstName,
                        middleNames,
                        dobDay,
                        dobMonth,
                        dobYear,
                        houseNumber,
                        lastName,
                        email,
                        street,
                        town,
                        region,
                        postCode,
                        country,
                        mobilePhoneNumber,
                        landlinePhoneNumber,
                        solicitorFirm,
                        solicitorEmail,
                        newsletter
                    }}
                    handleEditSuccess={handleEditSuccess} 
                    formRef={formRef}
                    setEditedProfile={setEditedProfile} />
                )
            }
            
        </div>
    )
}