import { useState, useCallback } from 'react';
const reader = new FileReader();

/**
 * 
 * @param {{name: string, ref: React Ref Element}} initImgs - Represents the initial images whose srcs come from file uploads.
 */
const useFileUpload = (setFormData, accepts, initImgData, initImgErrs) => {
    const [imgData, setImgData] = useState(initImgData ?? {});
    const [imgErrs, setImgErrs] = useState(initImgErrs ?? {});

    const removeImg = useCallback((imgName) => {
        if (imgData.imgName) {
            setImgData((prevImgData) => {
                let { [imgName]: prevImg, ...rest } = prevImgData;
    
                return rest;
            });
        }


        setImgErrs((prevImgErrs) => {
            let { [imgName]: prevErr, ...rest } = prevImgErrs;

            return rest;
        });
    }, []);

    const getFileData = useCallback((imgName, file, loadHandler) => {
        console.log(file);
        let err = false;
        if (!accepts.some((type) => file.name.endsWith(type))) {
            setImgErrs({...imgErrs, [imgName]: "This file does not match the required type(s)"});
            err = true;
        }
        else if (file.size >= 1048576) {
            setImgErrs({...imgErrs, [imgName]: "This image's size is over 1MB"});
            err = true;
        }

        if (err) {
            if (loadHandler) {
                loadHandler(null);
            }
            if (imgData[imgName]) {
                setImgData(prevImgData => {
                    let { [imgName]: prevImg, ...rest } = prevImgData;

                    return rest;
                })
            }
        }
        else {
            reader.onload = function() {
                let nameSplit = file.name.split(".");
                let type = nameSplit[nameSplit.length - 1];
                
                setImgData(prevImgData => { return { ...prevImgData, [imgName]: {
                    name: file.name,
                    data: reader.result,
                    type } }});
                setImgErrs(prevImgErrs => {
                    let { [imgName]: prevErr, ...rest } = prevImgErrs;

                    return rest;
                });

                if (loadHandler) {
                    console.log("found load handler!");
                    loadHandler(reader.result);
                }

                setFormData && setFormData(formData => { return {...formData, [imgName]: reader.result} });
            }
    
            reader.readAsDataURL(file);
        }
    }, []);



    return { imgData, imgErrs, removeImg, getFileData };
}

export default useFileUpload;