import React from 'react';
import { Link } from 'react-router-dom';
import BlockIcon from '@material-ui/icons/Block';

import InputButton from '@components/forms/InputButton';

export default function NotFoundPage() {
    return (
        <div className="flex flex-col items-center pt-32 px-12 text-center">
            <BlockIcon style={{fontSize: "6rem"}} className="text-air-red" />
            <h1 className="font-primary font-bold text-6xl">404 Not Found</h1>
            <p className="font-secondary font-bold text-3xl text-primary mb-12">The resource/web page you are looking for has not been found</p>
            <Link 
            to="/">
                <InputButton 
                submitText="Take me Home"
                type="button"
                rootClass="text-2xl" />
            </Link>
        </div>
    )
}