import { useEffect, useState } from "react";
import socketController from '@util/socket';

class ServerTimerManager {
  serverTime = 0
  subscribers = new Set()

  handler = (data) => {
    this.serverTime = data.server_ms
    this.notify()
  }

  start() {
    if (!socketController.isConnected()) {
        socketController.initializeSocket();
        socketController.addEventListener('server-time', this.handler ); 
    }
  }

  end() {
    if (socketController.isConnected()) {
        socketController.removeEventListener('server-time', this.handler ); 
        socketController.disconnect();
    }
  }

  notify() {
    this.subscribers.forEach(subscriber => subscriber(this.serverTime))
  }

  subscribe(subscriber) {
    this.subscribers.add(subscriber)
    this.notify()
    if (this.subscribers.size > 0) {
      this.start()
    }
  }

  unsubscribe(subscriber) {
    if( this.subscribers.has(subscriber) ) {
      this.subscribers.delete(subscriber)
      if (this.subscribers.size <= 0) {
        this.end()
      }
    }
  }

  clear() {
    this.subscribers.clear()
    this.end()
  }
}

const serverTimeManager = new ServerTimerManager()

export default function useServerTime() {
  const [serverTime, setServerTime] = useState(serverTimeManager.serverTime)

  useEffect(() => {
    serverTimeManager.subscribe(setServerTime)
    return () => serverTimeManager.unsubscribe(setServerTime)
  }, [])

  return serverTime
}