import React, { useState, createContext, useEffect, useContext, useRef  } from 'react';

import axios from '@util/axios';
import { USER_INACTIVE_IN_MS } from '@util/site-constants';
import AppContext from './AppContext';
import WarnInactiveNotification from '@components/WarnInactiveNotification';

const AuthContext = createContext();

/** 
    The context provider for authentication and user-related information.
    @state userType - 'bidder' or 'agent' or null if no user logged in!
    @state userData - user-related information (based on userType!)
    @state isUserChecked - Whether or not we have completed checking for user login.
    @ref userTypeRef - consistent userType (necessary for setTimeout, otherwise userType will be stale)
    @ref inactiveTimerRef - Timer object for becoming inactive.
*/
export const AuthContextProvider = ({ children }) => {
    const { setServerError, addNotification, forceSkipNotification } = useContext(AppContext);
    const [userType, setUserType] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isUserChecked, setUserChecked] = useState(false);
    const userTypeRef = useRef(null);
    const inactiveWarningTimerRef = useRef(null);

    /**
     * Turns off the current inactive timer.
     * Main use case: used in reset (below) + when user logs out from log out button on navbar
     */
    const turnOffInactiveTimer = () => {
        if (inactiveWarningTimerRef.current) {
            clearTimeout(inactiveWarningTimerRef.current);
        }
    }

    /**
     * Resets the timer for detecting an inactive user.
     * Main use case: API calls + any activity event.
     */
    const resetInactiveTimer = () => {
        turnOffInactiveTimer(); // Turn off current timer first (otherwise it will persist.)
        
        // inactiveTimerRef.current = setTimeout(logOutUser, USER_INACTIVE_IN_MS);
        inactiveWarningTimerRef.current = setTimeout(warnInactiveUser, USER_INACTIVE_IN_MS - (60 * 1000));
    };

    /**
     * Logs out user when user becomes inactive.
     * To account for possible time discrepancies, will
     * attempt to call logout API. If the cookie hasn't already
     * expired, then logout will succeed and cookie will be invalidated.
     * If the cookie has already expired at the end of setTimeout,
     * then logout will fail, but we then know that the user is already 'logged out'.
     * 
     * @param {boolean} force Whether or not we're forcing a logout due to not selecting an option.
     */
    const logOutUser = async (force) => {
        try {
            await axios.post(`/api/${userTypeRef.current}/logout`);

            setUserType(null);
            setUserData(null);
            
            if (!force) { // If we log out manually, close the current notification for inactivity so that the next notification can come in.
                forceSkipNotification();
            }
            addNotification(force ? 'Logged out for inactivity.' : 'Successfully logged out.');
        }
        catch (err) {
            forceSkipNotification();
            addNotification('Attempt to log out failed. Please refresh the page.');
        }
    };

    /**
     * Keeps user logged in for another duration of the cookie expiry time.
     */
    const keepUserLoggedIn = async () => {
        try {
            const res = await axios.post(`/api/${userTypeRef.current}/refreshLoginSession`);

            if (!res.data.status) {
                throw new Error(res.data.message);
            }

            forceSkipNotification();
            resetInactiveTimer();
        }
        catch (err) {
            forceSkipNotification();

            addNotification(`Attempt to keep logged in failed on our end. You will now be logged out. Error: ${err instanceof Error ? err.message : err.status}`);
            setUserType(null);
            setUserData(null);
        }
    }

    /**
     * 
     */
    const warnInactiveUser = () => {
        addNotification(
            <WarnInactiveNotification 
            handleLogOut={() => logOutUser(false)}
            handleKeepLoggedIn={keepUserLoggedIn} />
        , null, 60 * 1000, () => logOutUser(true));
    };



    // /* 
    //     Effect:
    //     Resets inactive timer
    // */
    // useEffect(() => {
    //     window.addEventListener('click', (e) => {
    //         resetInactiveTimer();
    //     });

    //     // Don't need to clean up this effect b/c it is always mounted until the browser tab closes!
    // }, []);

    /* 
        Effect:
        When a user is logged in, start the inactive timer.
    */
    useEffect(() => {
        if (userType !== null) {
            /* 
                In the case where a timer is running and the user has
                switched userTypes, make sure to clear the timeout before creating
                a new one!
            */
            resetInactiveTimer();
        }
    }, [userType]);

    /* 
        Effect:
        When userType becomes null (i.e when user is logged out), make sure
        to clear the inactive timer if it existed.
    */
    useEffect(() => {
        if (userType === null) {
            if (inactiveWarningTimerRef.current) {
                clearTimeout(inactiveWarningTimerRef.current);
            }
        }
    }, [userType]);

    /* 
        Effect:
        Make sure to update userTypeRef whenever userType changes so it's
        always up-to-date!
    */
    useEffect(() => {
        userTypeRef.current = userType;
    }, [userType]);

    // TODO Make sure to update once agency cookies are implemented!
    useEffect(() => {
        /**
         * Checks both agent and bidder data to determine user type for this session.
         * Bidder data will be checked via /api/bidder/data. 
         * Agent data is still based on localStorage (i.e. check for agentToken), but once
         * agency cookies get added, MAKE SURE TO UPDATE THIS! Will prob use Promise.all to make the requests simultaneously.
         */
        async function checkUserLogin() {
            try {                    
                const agentRes = await axios.post('/api/agent/data', null);
                
                if (!agentRes.data.status) {
                    const bidderRes = await axios.post('/api/bidder/data', null);

                    /* 
                        If cannot receive data (i.e. no current session), don't do anything. 
                        userType and userData will remain null.
                    */
                    if (bidderRes.data.status) { 
                        const bidderData = bidderRes.data.data;
                        const { 
                            myBids,
                            isVerified, // Bidder account has email verified
                            isApproved // Bidder account approved
                        } = bidderData; 
    
                        /*
                            If bidder has bids, make sure to create bidsMaxPrice (i.e. mapping 
                            of productId to max price, to be used for bidding logic!)
                        */
                        let bidsMaxPrice = {};
    
                        if (myBids.length) {
                            // socketController.initializeSocket();
                            let bidProductSet = new Set();
    
                            myBids.forEach((bid) => {
                                if (bidsMaxPrice[bid.productId]) {
                                    if (bid.price > bidsMaxPrice[bid.productId]) {
                                        bidsMaxPrice[bid.productId] = bid.price;
                                    }    
                                }
                                else {
                                    bidsMaxPrice[bid.productId] = bid.price;
                                }
    
                                bidProductSet.add(bid.productId);
                            });
                            // socketController.addEventListener('new-price', (data) => {
                            //     handleNewPrice(data, bidsMaxPriceRef.current);
                            // })
    
                            // for (let productId of bidProductSet) {
                            //     socketController.emitEvent('observer', {
                            //         productId
                            //     });
                            // }
                        }
    
                        // TODO Ask to change isApproved to a boolean. Don't know why it's an array!
                        setUserType('bidder');
                        setUserData({
                            ...bidderData,
                            isFullyRegistered: isVerified && isApproved.length,
                            bidsMaxPrice
                        }); 
                    }
                }
                else { // Get agent data
                    const agentData = agentRes.data.data;
                    
                    setUserType('agent');
                    setUserData(agentData);
                }
            }
            catch (e) {
                setServerError('Attempt to retrieve bidder data failed.');
            }

            setUserChecked(true);
        };

        checkUserLogin();
    }, []);

    return (
        <AuthContext.Provider value={{
            userType,
            setUserType,
            userData,
            setUserData,
            isUserChecked,
            resetInactiveTimer,
            turnOffInactiveTimer
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;